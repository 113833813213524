@import "../../assets/scss/variables.scss";

.ModalCustom {
  .modal-content {
    border: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    -o-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  }
}
