@import "../../assets/scss/variables.scss";

.bookmarkWrap {
	.btn-wrap {
		button {
			&.bookmark {
				padding: 10px 13px;

				.icon {
					margin-right: 0;
				}
			}
		}
	}

	.TownUl {
		ul {
			display: flex;
			grid-gap: 4px;
			padding: 8px 0px 0px !important;
			flex-wrap: wrap;

			li {
				padding: 4px 9px;
				border-radius: 100px;
				background: #f8f8f8;
				font-size: 12px;
				font-weight: 500;
			}
		}
	}

	.no_data {
		text-align: center;
		font-family: $SFPRODISPLAY;
		font-style: normal;
		font-weight: 400;
		font-size: 40px;
		line-height: 42px;
		color: $TEXT_PURPLE;
		width: 100%;
		padding-top: 25px;

		p {
			margin-top: 10px;
		}
	}
}
