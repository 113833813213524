@import "./assets/scss/variables.scss";

@import "./assets/scss/variables.scss";

@font-face {
	font-family: $SFPRODISPLAY;
	src: url("./assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
		url("./assets/fonts/SFProDisplay-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

// icon
@font-face {
	font-family: "angus_icon";
	src: url("./assets/fonts/angus_icon.eot?lwszft");
	src: url("./assets/fonts/angus_icon.eot?lwszft#iefix")
			format("embedded-opentype"),
		url("./assets/fonts/angus_icon.ttf?lwszft") format("truetype"),
		url("./assets/fonts/angus_icon.woff?lwszft") format("woff"),
		url("./assets/fonts/angus_icon.svg?lwszft#angus_icon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "angus_icon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-business:before {
	content: "\e900";
}
.icon-calender:before {
	content: "\e901";
}
.icon-edit:before {
	content: "\e902";
}
.icon-event:before {
	content: "\e903";
}
.icon-pin:before {
	content: "\e904";
}
.icon-warning:before {
	content: "\e905";
}
.icon-watch:before {
	content: "\e906";
}
.icon-twitter:before {
	content: "\e907";
}
.icon-search:before {
	content: "\e908";
}
.icon-instagram:before {
	content: "\e909";
}
.icon-facebook:before {
	content: "\e90a";
}
.icon-eye-hide:before {
	content: "\e90b";
}
.icon-email:before {
	content: "\e90c";
}
.icon-arrow-down:before {
	content: "\e90d";
}
.icon-close:before {
	content: "\e90e";
}
.icon-check:before {
	content: "\e90f";
}
.icon-eye-show:before {
	content: "\e910";
}
.icon-link:before {
	content: "\e911";
}
.icon-linkedin:before {
	content: "\e912";
}
.icon-info:before {
	content: "\e913";
}
.icon-check-outline:before {
	content: "\e914";
}
.icon-delete:before {
	content: "\e915";
}
.icon-pencil:before {
	content: "\e916";
}
.icon-arrow-right:before {
	content: "\e917";
}
.icon-close-cricle:before {
	content: "\e918";
}
.icon-phone:before {
	content: "\e919";
}
.icon-success:before {
	content: "\e91a";
}
.icon-sorting:before {
	content: "\e91b";
}
.icon-calender-outline-fill:before {
	content: "\e91c";
}
.icon-pin-outline:before {
	content: "\e91d";
}
.icon-setting:before {
	content: "\e91e";
}
.icon-menu:before {
	content: "\e91f";
}
.icon-back:before {
	content: "\e920";
}
.icon-activity-pin:before {
	content: "\e921";
}
.icon-bin:before {
	content: "\e922";
}
.icon-bookmark-outline:before {
	content: "\e923";
}
.icon-bookmark:before {
	content: "\e924";
}
.icon-calendar:before {
	content: "\e925";
}
.icon-call:before {
	content: "\e926";
}
.icon-coolicon:before {
	content: "\e927";
}
.icon-dots:before {
	content: "\e928";
}
.icon-download:before {
	content: "\e929";
}
.icon-filter:before {
	content: "\e92a";
}
.icon-home:before {
	content: "\e92b";
}
.icon-info_circle_outline:before {
	content: "\e92c";
}
.icon-mail:before {
	content: "\e92d";
}
.icon-marker:before {
	content: "\e92e";
}
.icon-privacy-policy:before {
	content: "\e92f";
}
.icon-service-pin:before {
	content: "\e930";
}
.icon-supporters:before {
	content: "\e931";
}
.icon-Target:before {
	content: "\e932";
}
.icon-server:before {
	content: "\e933";
}

/* width */
::-webkit-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #073180;
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #073180;
	border-radius: 8px;
}

body {
	margin: 0;
	padding: 0;
	font-family: $SFPROTEXT !important;
	background-color: $BG_GREY2;
	color: $TEXT_BLACK;
	height: 100vh;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
	font-family: SF Pro Display;
	font-weight: 400;
	&.rightSidebarshow {
		.header {
			max-width: calc(100% - 792px);
		}
		.pageMain {
			width: calc(100% - 475px);
		}
	}
}
a {
	text-decoration: none !important;
	color: $TEXT_GREY;
}
a:hover,
a:focus {
	color: $TEXT_YELLOW;
}
ul {
	list-style: none;
	margin: 0 !important;
	padding: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.pageMain {
	float: left;
	position: relative;
	width: 100%;
	padding-left: 0px;
	transition: 0.3s all;

	.page {
		float: left;
		position: relative;
		width: 100%;
		background-color: $BG_Body;
	}
}

.suggest-port {
	.modal-dialog {
		width: 920px;
		max-width: 100%;
	}
}

.suggest-full {
	width: 100%;
	float: left;

	form {
		display: block;
		width: 100%;
		display: flex;
		justify-content: space-between;

		.suggest-part {
			width: 48%;
			float: left;

			.textField {
				margin-bottom: 20px;
			}

			h2 {
				margin: 0 0 25px 0;
				text-align: center;
				font-family: $SFPROTEXT;
				font-size: $FONT_24;
				color: $TEXT_RED;
			}

			.uploadedImg {
				width: 100%;
				cursor: pointer;
				height: 100px;
				display: flex;
				align-items: center;
				justify-content: center;

				.uploadedImgText {
					text-align: center;

					i {
						color: $TEXT_BLACK;
						font-size: $FONT_22;
					}

					p {
						margin: 0;
						font-family: $SFPROTEXT;
						font-size: $FONT_12;
						color: $TEXT_BLACK;
						span {
							font-family: $SFPROTEXT;
							font-size: $FONT_12;
							color: $TEXT_RED;
						}
					}
					.upload_port_harbour {
						margin: 5px 0 0 0;
						font-family: $SFPROTEXT;
						font-size: $FONT_12;
						color: $TEXT_GREEN;
					}
				}

				img {
					width: 85px;
					height: 85px;
					margin: 0;
					padding: 0;
					object-fit: contain;
				}

				.imageDetails {
					padding-left: 10px;

					.fileName {
						font-family: $SFPROTEXT;
						font-size: $FONT_12;
						color: $TEXT_BLACK;
						margin: 0;
						text-align: left;
						max-width: 200px;
						word-break: break-all;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.fileSize {
						font-family: $SFPROTEXT;
						font-size: $FONT_12;
						margin: 0;
						text-align: left;
						max-width: 200px;
						word-break: break-all;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.changeText {
						font-family: $SFPROTEXT;
						font-size: $FONT_12;
						margin: 0;
						text-align: left;
					}
				}
			}

			.dropzoneCustom {
				display: block;
				width: 100%;
				border-radius: 10px;
				background-color: $BG_RED;
				border: 2px dashed $BG_GREEN;
				padding: 8px 0;

				.txtDropzone {
					position: relative;
					width: 100%;
					&:focus {
						outline: none;
					}
					.imgBoxEmpty {
						&:focus {
							outline: none;
						}
					}
				}
			}

			.footer-button {
				margin: 40px 0;
				display: flex;
				align-items: center;
				justify-content: center;
				.custombtn {
					margin: 0 10px;
					padding-left: 10px !important;
					padding-right: 10px !important;
					min-width: inherit;
					width: auto !important;

					i {
						margin-right: 5px;
						font-size: 20px !important;
						top: 0;
					}
				}
				.cancel {
					background-color: $BG_RED;
					color: $TEXT_GREEN !important;
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						margin-right: 5px;
						font-size: 20px !important;
						top: 0;
					}
				}
			}
		}
	}
}

.category_modal {
	width: 317px;
	.modal-content {
		width: 92%;
		margin: 0 auto;
		.modal-header {
			background-color: $BG_SECONDARY_ONE;
			align-items: center;
			.modal-title {
				font-size: $FONT_16;
				line-height: 20px;
				font-family: $SFPROTEXT;
				font-weight: 600;
				color: $TEXT_WHITE;
			}
			.icon-close {
				outline: none;
				border: none;
				background-color: transparent;
				color: $TEXT_WHITE;
				font-size: $FONT_14;
				padding: 0px;
			}
		}
		.modal-body {
			width: 100%;
			float: left;
			padding: 24px 24px;
			.labelTitle {
				float: left;
				font-size: 16px;
				font-family: $SFPROTEXT;
				font-weight: 400;
				line-height: 20px;
				color: $TEXT_BLUE_DARK;
				padding-bottom: 8px;
			}
			.textField {
				.form-control {
					padding-right: 16px;
				}
				.iconSection {
					display: none;
				}
			}
			.modal-footer {
				width: 100%;
				padding: 0px;
				margin: 24px 0 0 0;
				justify-content: space-between;
				padding-top: 24px;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 10px;
				.custombtn {
					// width: 117px;
					margin: 0px;
				}
			}
		}
	}
}

.calenderContainer {
	width: 48%;

	.custom-date-input {
		background: $BG_WHITE;
		font-family: $SFPROTEXT;
		color: $TEXT_GREEN;
		font-size: $FONT_14;
		padding: 12.5px 14px;
		padding-right: 40px;
		height: auto;
		width: 100%;
		border-radius: 8px;
		border-width: 1px;
		border-color: $BG_GREEN;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-align: left;
		&::after {
			position: absolute;
			top: 8px;
			right: 16px;
			background-color: transparent;
			color: $BG_RED;
			height: 18px;
			width: 18px;
			padding: 0;
			font-size: $FONT_20;
			content: "\e91f";
			font-family: "zeadog" !important;
		}
	}

	.custom-date-placeholder {
		color: $BG_RED;
	}

	.react-datepicker__close-icon {
		padding: 0;
		margin-right: 12px;

		&::after {
			background-color: transparent;
			color: $BG_RED;
			height: 18px;
			width: 18px;
			padding: 0;
			font-size: $FONT_16;
			content: "\e91f";
			font-family: "zeadog" !important;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-o-transform: rotate(45deg);
		}
	}
}
.modal.edit_privacy_policy .modal-dialog .modal-content {
	background: #fff !important;
}
.edit_privacy_policy {
	.modal-dialog {
		width: auto !important;
		max-width: 770px;
		min-width: 770px;
		.modal-content {
			background-color: $BG_WHITE !important;
			padding: 40px 80px 80px;
			.icon-warning {
				display: none;
			}
			.modal-header {
				text-align: left;
				p {
					margin: 0;
					font-family: $SFPROTEXT;
					font-size: 20px;
					line-height: 24px;
					color: $TEXT_SECONDARY_ONE;
					font-weight: 600;
					padding: 0 0 16px;
				}
			}
			.modal-body {
				.date_txt {
					position: absolute;
					top: -45px;
					right: 0;
					font-size: 14px;
					line-height: 16px;
					color: $DATETEXT;
				}

				.textareaBorder {
					width: 100%;
					float: left;
					border: 1px solid $INPUT_BORDER;
					-webkit-box-shadow: 0px 12px 24px $BOXSHADOW;
					-moz-box-shadow: 0px 12px 24px $BOXSHADOW;
					-ms-box-shadow: 0px 12px 24px $BOXSHADOW;
					-o-box-shadow: 0px 12px 24px $BOXSHADOW;
					box-shadow: 0px 12px 24px $BOXSHADOW;
					.DraftEditor-editorContainer,
					.DraftEditor-root,
					.public-DraftEditor-content {
						height: 100%;
					}
					.public-DraftStyleDefault-ol,
					.public-DraftStyleDefault-ul {
						width: 100%;
						float: left;
					}
					.public-DraftStyleDefault-orderedListItem {
						display: flex;
						width: 100%;
					}
					.rdw-link-modal {
						height: 255px;
					}
					.DraftEditor-root {
						width: 100%;
						float: left;
						.public-DraftStyleDefault-block {
							font-size: $FONT_14;
							font-weight: 400;
							line-height: 20px;
							font-family: $SFPROTEXT;
							width: 100%;
							float: left;
							color: $TEXT_SECONDARY_TWO;
							margin: 0px;
						}
						.public-DraftEditorPlaceholder-inner {
							font-size: $FONT_14;
							font-weight: 400;
							line-height: 16px;
							font-family: $SFPROTEXT;
							width: 100%;
							float: left;
							color: $PLACEHOLDER;
						}
					}

					.rdw-editor-toolbar {
						border-top: none;
						border-left: none;
						border-right: none;
						padding: 10px 16px;
						margin-bottom: 0px;
						border-bottom: 1px solid $INPUT_BORDER;
						.rdw-inline-wrapper {
							margin: 0px;
						}
						.rdw-list-wrapper {
							margin: 0px;
						}
						.rdw-link-wrapper {
							margin: 0px;
						}
					}
					.wrapperClassName {
						.resizeClass {
							padding: 16px 16px;
							height: 450px;
							overflow: auto;
							resize: none !important;
						}
					}
				}
				p {
					padding-bottom: 10px;
				}
			}
			.custombtnfield {
				display: flex;
				width: 100%;
				justify-content: flex-end;
				padding-top: 24px;
				.custombtn {
					width: 164px;
					min-height: 36px;
					margin-left: 16px;
					font-weight: 600;
					padding: 0px;
					border-radius: 8px;
				}
			}
		}
	}
}
.confirm-logout {
	.modal-dialog {
		.modal-content {
			.modal-body {
				p {
					padding-bottom: 10px;
				}
			}
			.modal-footer {
				.cancle {
					background-color: $BG_WHITE;
					color: $TEXT_GREEN !important;
					border: 1px solid $BG_RED !important;
				}
				.active {
					background-color: $BG_GREEN;
					color: $TEXT_WHITE !important;
					border: 1px solid $BG_GREEN !important;
				}
			}
		}
	}
}
.confirmModal {
	.modal-dialog {
		.modal-content {
			.modal-header {
				&::before {
					background-color: $BG_RED;
				}
			}
			.modal-footer {
				.cancle {
					background-color: $BG_WHITE;
					color: $TEXT_GREEN !important;
					border: 1px solid $BG_RED !important;
				}
				.active {
					background-color: $BG_GREEN;
					color: $TEXT_WHITE !important;
					border: 1px solid $BG_GREEN !important;
				}
			}
		}
	}
}

.disabledModal {
	.modal-dialog {
		.modal-content {
			.modal-header {
				&::before {
					background-color: $BG_GREEN;
				}
			}
		}
	}
}
.no_categroy {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.no_categroy_logo {
		padding-bottom: 60px;
	}
	.no_categroy_txt {
		font-family: $SFPROTEXT;
		font-weight: 400;
		font-size: $FONT_16;
		line-height: 20px;
		color: $NOCATEGORYTEXT;
	}
}
.no_categroy_row {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.no_categroy_logo {
		padding-bottom: 22px;
	}
	.no_categroy_txt {
		font-family: $SFPROTEXT;
		font-weight: 400;
		font-size: $FONT_16;
		line-height: 20px;
		color: $NOCATEGORYTEXT;
		text-align: center;
		padding: 0 20px;
	}
}
.no_business_row {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.no_categroy_logo {
		padding-top: 85px;
		padding-bottom: 22px;
	}
	.no_categroy_txt {
		font-family: $SFPROTEXT;
		font-weight: 400;
		font-size: $FONT_16;
		line-height: 20px;
		color: $NOCATEGORYTEXT;
		text-align: center;
		padding: 0 20px;
	}
}
.add_business_modal {
	.modal-dialog {
		max-width: 770px;
		width: 96%;
	}
}

.overLayMenu {
	background: transparent;
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1;
}
.date-filter-main {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 16px;
	&.disabled {
		pointer-events: none;
	}
	&:last-child {
		padding-bottom: 0px;
	}
	.datetime_start_title {
		width: 100%;
		font-size: $FONT_16;
		line-height: 20px;
		color: $TEXT_BLUE_DARK;
		padding-bottom: 8px;
		&::after {
			content: "*";
			position: relative;
			color: $TEXT_RED;
			font-size: $FONT_16;
		}
	}
	.datetime_title {
		width: 100%;
		font-size: $FONT_16;
		line-height: 20px;
		color: $TEXT_BLUE_DARK;
		padding-bottom: 8px;
	}

	.calenderCustomContainer {
		width: 49%;
		.custom-date-input {
			width: 100%;
			background-color: transparent;
			border: 1px solid $INPUT_BORDER;
			text-align: left;
			border-radius: 8px;
			padding: 12px 16px;
			font-size: $FONT_14;
			color: $TEXT_SECONDARY_TWO;
			&::after {
				content: "\e901";
				font-family: "angus_icon";
				font-size: 20px;
				color: $ICONCOLOR;
				position: absolute;
				right: 16px;
				top: 8px;
			}
			.custom-date-placeholder {
				color: $PLACEHOLDER;
			}
		}
	}
	.calenderContainer {
		width: 49%;
		.custom-time-input {
			width: 100%;
			background-color: transparent;
			border: 1px solid $INPUT_BORDER;
			text-align: left;
			border-radius: 8px;
			padding: 12px 16px;
			font-size: $FONT_14;
			color: $TEXT_SECONDARY_TWO;
			&::after {
				content: "\e906";
				font-family: "angus_icon";
				font-size: 20px;
				color: $ICONCOLOR;
				position: absolute;
				right: 16px;
				top: 8px;
			}

			.custom-date-placeholder {
				color: $PLACEHOLDER;
			}
		}
	}
}

.searchMainDropdown {
	width: 100%;
	float: left;
	position: relative;
	margin-bottom: 16px;
	.icon-close {
		position: absolute;
		top: 14px;
		right: 16px;
		z-index: 1;
		background: $BG_WHITE;
		border: none;
		color: $TEXT_SECONDARY_TWO;
		font-size: $FONT_15;
		padding: 2px;
	}
	.select-search-container {
		width: 100%;
		height: 48px;
		.select-search-value {
			input:disabled {
				color: $INPUTDISABLE;
				&::-webkit-input-placeholder {
					color: $INPUTDISABLE;
				}
				&::-moz-placeholder {
					color: $INPUTDISABLE;
				}

				&:-ms-input-placeholder {
					color: $INPUTDISABLE;
				}

				&:-moz-placeholder {
					color: $INPUTDISABLE;
				}
			}

			&::after {
				border: none !important;
				position: absolute;
				content: "\e908";
				font-family: "angus_icon";
				transform: none !important;
				color: $PLACEHOLDER;
				top: 10px;
				right: 14px;
				font-size: $FONT_18;
				width: auto;
				height: auto;
			}
		}
		.select-search-select {
			z-index: 99 !important;
			top: 50px !important;
			border: 1px solid $INPUT_BORDER;
			.select-search-options {
				margin: 0 !important;
				padding: 0 !important;
				max-height: 200px !important;
				overflow-y: auto;
				overflow-x: hidden;
				.select-search-row {
					border: none;
					.select-search-option {
						font-family: $SFPROTEXT;
						color: $TEXT_SECONDARY_TWO;
						font-size: $FONT_14;
						padding: 12px 14px;
						height: auto;
						&:hover {
							background: $BG_SECONDARY_ONE;
							color: $TEXT_WHITE;
						}
					}
					.select-search-is-selected {
						background: $BG_SECONDARY_ONE;
						color: $TEXT_WHITE;
						&:hover {
							background: $BG_SECONDARY_ONE;
							color: $TEXT_WHITE;
						}
					}
				}
			}
		}
	}
}
.phone_view {
	width: 100%;
	float: left;
	margin-bottom: 16px;
	.errorMsg {
		width: 100%;
		float: left;
		font-size: 12px;
		text-align: left;
		color: $BG_WHITE;
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.3s ease-out;
		&.show {
			padding: 8px 0 0;
			color: $BG_RED;
			max-height: 130px;
			transition: max-height 0.3s ease-in;
		}
	}
}
.phone_input_row {
	position: relative;
	width: 100%;
	float: left;
	z-index: 99;
	border: 1px solid $INPUT_BORDER;
	border-radius: 8px;
	display: flex;
	align-items: center;
	&.error_show {
		border: 1px solid $BG_RED;
	}
	.icon-phone {
		position: relative;
		font-size: 20px;
		cursor: pointer;
		color: $PHONEICONCOLOR;
		padding: 0 10px;
	}
	.react-tel-input {
		width: 100%;
		outline: none;
		.special-label {
			display: none;
		}
		.country-list {
			z-index: 11;
			left: -40px;
			border-radius: 5px;
			.search {
				padding-right: 10px;
				.search-box {
					width: 100%;
					padding: 8px 8px 8px;
					font-family: $SFPROTEXT;
					font-size: 14px;
					border-color: $INPUT_BORDER;
				}
			}
			.flag {
				display: none;
			}
			.country-name {
				font-family: $SFPROTEXT;
				color: $TEXT_SECONDARY_TWO;
				font-size: $FONT_14;
			}
			.dial-code {
				font-family: $SFPROTEXT;
				color: $TEXT_SECONDARY_TWO !important;
				font-size: $FONT_14;
			}
			.country {
				text-align: left;
			}
		}

		.selected-flag {
			background: transparent !important;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 30px;
			padding: 0px;

			.flag {
				opacity: 1;
				position: relative;
				top: 0;
				background-image: none;
				.arrow {
					display: none;
					position: relative;
					top: 0;
					margin-top: 0px;
					left: 0px;
					width: 0;
					height: 0;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 6px solid #555;
				}
			}
		}

		.flag-dropdown {
			border: none;
			background: transparent !important;
		}

		.form-control {
			width: 100%;
			height: 47px;
			padding: 0 0px !important;
			border: none;
			text-align: left;
			background: transparent;
			outline: none;
			box-shadow: none;
			font-size: 14px;
			font-family: $SFPROTEXT;
			&::placeholder {
				color: $PLACEHOLDER;
				opacity: 1;
			}
			&:-ms-input-placeholder {
				color: $PLACEHOLDER;
			}

			&::-ms-input-placeholder {
				color: $PLACEHOLDER;
			}
		}
	}

	.TextFieldNoRequired {
		width: calc(100% - 100px);
		margin-left: 100px;
		.form-control {
			padding-left: 100px;
			padding-top: 11px;
		}
	}
}

.select-search-select .select-search-options {
	margin: 0 !important;
	padding: 0 !important;
	max-height: 200px !important;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: $SEARCHOPTION !important;
}

.select-search-select {
	border: 0px;
}

.select-search-select {
	z-index: 99 !important;
	top: 50px !important;
	border: 1px solid $SEARCHTEXT !important;
	box-shadow: 0px 0px;
}

button.select-search-option {
	font-family: "SF Pro Text";
	color: $BG_BLUE;
	font-size: 14px;
	padding: 12px 14px;
	height: auto;
}

.googleDropdown .css-13cymwt-control,
.googleDropdown .css-t3ipsp-control,
.googleDropdown .css-t3ipsp-control {
	background: transparent;
	font-family: $SFPROTEXT;
	color: $BG_RED;
	font-size: 14px;
	padding: 8.5px 14px;
	padding-right: 0;
	height: auto;
	width: 100%;
	border-radius: 8px 8px 0px 0px;
	border: 1px solid #c7d1e3;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	box-shadow: none;
	border-bottom: 0px;
	&:hover {
		border-bottom: 0px solid #c7d1e3;
	}
	.css-qbdosj-Input,
	.css-1fdsijx-ValueContainer {
		margin: 0px;
		padding: 0px;
	}
}

.googleDropdown .css-13cymwt-control .css-1fdsijx-ValueContainer {
	padding: 0px;
}

.googleDropdown .css-qbdosj-Input {
	margin: 0px;
	padding: 0px;
}

.CustomAddEvents
	.select-search-container
	input.select-search-input::placeholder {
	font-size: 14px;
	color: $PLACEHOLDER;
	opacity: 1;
}
.CustomAddEvents .select-search-container input.select-search-input {
	font-size: 14px;
	color: $BG_BLUE !important;
	opacity: 1;
}

button.select-search-option.select-search-is-selected {
	background: #320e3b;
	color: $BG_WHITE;
}

.ColorRed {
	color: $TEXT_RED;
}

// input[type="password"] {
// 	-webkit-text-security: disc;
// 	font-family: monospace !important;
// 	font-size: 35px !important;
// 	min-height: 47px;
// 	display: flex;
// 	align-items: center;
// 	max-height: 47px;
// 	letter-spacing: -6px;
// 	color: $TEXT_GREY_PLACEHOLDER !important;
// }

.react-datepicker__time-box ul.react-datepicker__time-list {
	display: block !important;
}

@media (prefers-color-scheme: dark) {
	.select-search-container {
		--select-search-background: #fff;
		--select-search-text: #000;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	body.rightSidebarshow .header,
	body.rightSidebarshow .pageMain {
		max-width: calc(100% - 0px);
		width: calc(100% - 0px);
	}

	.dashboardpage .category_btn {
		z-index: 11 !important;
	}
	.rightSidebarshow .header {
		z-index: 111 !important;
	}
	.rightSidebarshow .CustomSideBar {
		z-index: 1111;
		position: fixed;
	}
}
@media only screen and (min-width: 1201px) and (max-width: 1872px) {
	// body.rightSidebarshow .header, body.rightSidebarshow .pageMain {
	// 	max-width: calc(100% - 0px);
	// 	width: calc(100% - 0px);
	// }

	.dashboardpage .category_btn {
		z-index: 11 !important;
	}
	.rightSidebarshow .header {
		z-index: 111 !important;
	}
	.rightSidebarshow .CustomSideBar {
		z-index: 1111;
		position: fixed;
	}
	.header .navbar .navbar-collapse .navbar-nav .nav-link {
		width: fit-content;
	}
	.header .navbar .profiledropdown .avtar-name {
		word-break: break-all;
		white-space: pre;
	}
}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {
	body.rightSidebarshow .header,
	body.rightSidebarshow .pageMain {
		max-width: calc(100% - 0px);
		width: calc(100% - 0px);
	}
}

.SidebarClose {
	position: absolute;
	right: 0;
	margin: 0px 20px;
	border: 0px;
	background-color: transparent !important;
	padding: 0px;
	font-size: 14px;
	min-height: 52px;
	max-height: 52px;
}

.fullTextBox {
	padding-right: 16px !important;
}
