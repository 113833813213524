@import "../../assets/scss/variables.scss";

.CustomBusinessListing {
	width: 100%;
	float: left;
	.business_container {
		width: 100%;
		float: left;
		padding-left: 5px;
		padding-right: 5px;
		.business_title {
			width: 100%;
			float: left;
			font-size: $FONT_20;
			line-height: 24px;
			font-weight: 600;
			font-family: $SFPROTEXT;
			color: $TEXT_SECONDARY_ONE;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 40px;
			padding-bottom: 24px;
			span {
				display: none;
				&.line_border {
					margin: 0 10px;
				}
			}
		}
		.search_row {
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 16px;
			display: flex;
			justify-content: space-between;
			width: 100%;
			.searchboxMain {
				width: 455px;
			}
			.right_btn_business {
				display: flex;
				justify-content: flex-end;
				.total_business_row {
					display: flex;
					align-items: center;
					padding-right: 16px;
					.count_title {
						float: left;
						font-size: $FONT_14;
						color: $TEXT_BLUE_DARK;
						font-family: $SFPROTEXT;
						font-weight: 400;
						line-height: 18px;
						display: flex;
						align-items: center;
					}
					.count_number {
						margin-left: 8px;
						font-size: $FONT_12;
						line-height: 14px;
						color: $TEXT_SECONDARY_ONE;
						background-color: $BG_WHITE;
						padding: 4px 14px;
						border-radius: 30px;
					}
				}
				.outline_btn {
					width: 158px;
					background-color: transparent;
					padding-left: 10px;
					padding-right: 10px;
					text-align: center;
				}
			}
		}
	}
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
	.CustomBusinessListing {
		.business_container {
			.search_row {
				.searchboxMain {
					width: 50%;
				}
			}
		}
	}
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
	.CustomBusinessListing {
		.business_container {
			.search_row {
				.searchboxMain {
					width: 300px;
				}
			}
		}
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.CustomBusinessListing {
		.business_container {
			.search_row {
				.searchboxMain {
					width: 350px;
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.CustomBusinessListing {
		.business_container {
			.business_title {
				display: flex;
				span {
					display: flex;
				}
			}
			.search_row {
				.searchboxMain {
					width: 340px;
				}
			}
		}
	}
}
