@import "../../assets/scss/variables.scss";

.textField {
	position: relative;
	width: 100%;
	float: left;
	display: block;
	margin-bottom: 16px;
	input {
		position: relative;
		z-index: 0;
		background: transparent;
		border: 1px solid #aaa;
		padding: 12px 16px;
	}
	label {
		position: absolute;
		left: 16px;
		top: 12px;
		margin: 0px;
		z-index: 1;
		color: $PLACEHOLDER;
		font-family: $SFPROTEXT;
		font-weight: 400;
		font-size: $FONT_14;
		line-height: 24px;
		pointer-events: none;
	}
	label::after {
		content: "*";
		color: red;
	}
	input[required]:valid + label {
		display: none;
	}
	textarea[required]:valid + label {
		display: none;
	}
	.form-control {
		background: transparent;
		font-family: $SFPROTEXT;
		color: $TEXT_SECONDARY_TWO;
		font-size: $FONT_14;
		padding: 12px 16px;
		padding-right: 40px;
		height: auto;
		width: 100%;
		border-radius: 8px;
		border: 1px solid $INPUT_BORDER;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;

		&.error {
			border: 1px solid $BG_RED;
			&:focus {
				border: 1px solid $BG_RED;
			}
		}

		&:focus {
			outline: none;
			box-shadow: none;
			border: 1px solid $INPUT_BORDER;
		}

		&::placeholder {
			color: $PLACEHOLDER;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: $PLACEHOLDER;
		}

		&::-ms-input-placeholder {
			color: $PLACEHOLDER;
		}
	}

	.iconSection {
		position: absolute;
		top: 0;
		right: 0;
		width: 34px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		z-index: 11;
		&.left_icon {
			position: absolute;
			top: 0;
			left: 0;
			width: 34px;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			z-index: 11;
		}
	}

	.icon-css {
		position: relative;
		font-size: $FONT_20;
		cursor: pointer;
		color: $PLACEHOLDER;
		&.icon-check {
			font-size: $FONT_16;
			color: $TEXT_GREEN_LIGHT;
		}
	}
	.icon-left {
		position: relative;
		font-size: $FONT_20;
		cursor: pointer;
		color: $PLACEHOLDER;
	}

	.errorMsg {
		position: relative;
		width: 100%;
		left: 0;
		display: block;
		font-size: $FONT_12;
		text-align: left;
		padding: 0;
		margin: 0;
		color: $TEXT_WHITE;
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.3s ease-out;
		font-family: $SFPROTEXT;
	}

	.errorMsg.show {
		padding: 8px 0 0;
		max-height: 130px;
		transition: max-height 0.3s ease-in;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		color: $TEXT_RED;

		.red,
		.green {
			display: flex;
			padding: 5px 0;
			width: 100%;
			align-items: center;
			justify-content: flex-start;
			position: relative;
			&::before {
				position: relative;
				left: 1px;
				top: 0;
				margin-right: 8px;
			}
		}

		.red {
			span {
				color: $TEXT_RED;
			}
			&::before {
				content: "\e913";
				font-family: "angus_icon";
				color: $TEXT_RED;
				font-size: $FONT_14;
			}
		}

		.green {
			span {
				color: $TEXT_SECONDARY_TWO;
			}
			&::before {
				content: "\e914";
				font-family: "angus_icon";
				font-size: $FONT_14;
				color: $TEXT_SECONDARY_TWO;
			}
		}
	}
}
