@import "../../assets/scss/variables.scss";

.CustomAddBusiness {
  padding: 20px 80px;
   width: 100%;
  float: left;
  

  .add_Business {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    
}
  .add_business_container {
    width: 771px;
    float: left;
    padding-bottom: 20px;
    
  }
  .add_business_card {
    width: 100%;
    float: left;
    background-color: $BG_WHITE;
    border-radius: 8px;
    padding: 20px 65px;
  }
  .add_business_title {
    font-size: $FONT_20;
    font-weight: 600;
    color: $TEXT_SECONDARY_ONE;
    text-align: center;
    padding: 0px;
    line-height: 24px;
  }
  .MainScrolldiv {
    margin-bottom: 26px;
    & > div {
      margin-bottom: 10px !important;
      margin-right: 0px !important;
      padding-right: 6px !important;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 8px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: $TEXT_PURPLE;
      border-radius: 8px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $TEXT_PURPLE;
      border-radius: 8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $BG_PURPLE;
    }
    .labelTitle {
      font-size: $FONT_18;
      font-weight: 400;
      margin-bottom: 8px;
      color: $TEXT_SECONDARY_ONE;
    }

    .textField {
      margin-bottom: 0px;
      float: unset;
      .form-control {
        padding: 12px 16px;
      }
      label {
        color: $PLACEHOLDER;
        font-size: $FONT_14;
        line-height: 24px;
      }
    }

    .businessName_count_wrapper {
      margin-bottom: 15px;

      .businessName_count {
        font-size: 14px;
        text-align: right;
        color: $DATETEXT;
        font-weight: 400;
        padding-top: 4px;
      }
      .errorMsg {
        position: absolute;
        padding-top: 0px !important;
        &.show {
          bottom: -24px;
          padding-top: 4px !important;
        }
      }
    }

    .discription_txt {
      position: relative;
      margin-bottom: 15px;
      .textField {
        textarea {
          min-height: 96px;
          &.error {
            margin-bottom: 38px;
          }
        }
      }
      .errorMsg {
        position: absolute;
        padding-top: 0px !important;
        &.show {
          bottom: -24px;
          padding-top: 4px !important;
        }
      }
      .textarea_count {
        position: absolute;
        font-size: $FONT_14;
        text-align: right;
        color: $PLACEHOLDER;
        font-weight: 400;
        padding-top: 0px;
        bottom: 5px;
        right: 10px;
      }
    }

    .colorDropdownWrapper {
      position: relative;
      .arrow-dropdown {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 14px;
        color: $DATETEXT;
      }
      .dropdownData {
        padding: 8px;
        .dropdown-item {
          background: #fff;
          display: flex !important;
          grid-gap: 10px;
          padding: 10px 10px !important;
          border-radius: 8px;
          border-bottom: 0px !important;
          &:hover {
            background-color: $DROPDOWNHOVER !important;
            color: $TEXT_TITLE !important;
          }
        }
        .cricle_view {
          width: 16px;
          height: 16px;
          border-radius: 100px;
        }
        /* width */
        &::-webkit-scrollbar {
          width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $TEXT_PURPLE;
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $BG_PURPLE;
        }
      }
      .dropdown-menu {
        box-shadow: 0px 0px transparent;
        border: 1px solid $BORDERCOLOR;
      }
      .cricle_select_view {
        width: 16px;
        height: 16px;
        display: block;
        border-radius: 100px;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        z-index: 1;
        left: 15px;
      }
      .customDropdown {
        button {
          padding: 12px 40px !important;
        }
      }
      .dropdownData {
        background: #ffffff;
        border-radius: 8px;
      }
    }

    .townWrapper {
      position: relative;
      .arrow-dropdown {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 14px;
        color: $DATETEXT;
      }
    }

    .onlineWrapper {
      display: flex;
      grid-gap: 10px;
      margin-bottom: 36px;
      .OnlineSwitchWrapper {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        padding: 10px 16px;
        border: 1px solid $INPUT_BORDER;
        border-radius: 8px;
        max-height: 47px;
        min-height: 47px;
        p {
          margin: 0px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .ServiceaddressWrapper {
      margin-bottom: 36px;
      .textField {
        margin-bottom: 15px;
      }
      .location_part {
        border-radius: 8px;
        overflow: hidden;
        .css-13cymwt-control,
        .css-t3ipsp-control {
          min-height: 47px;
          padding: 12px 16px !important;
          border: 1px solid $INPUT_BORDER !important;
          &:hover {
            border: 0px;
          }
          .css-1fdsijx-ValueContainer {
            padding: 0px;
          }
        }

        .css-1hb7zxy-IndicatorsContainer {
          display: none;
        }

        #react-select-2-listbox {
          margin: 0px;
        }
        .css-1n6sfyn-MenuList {
          padding: 8px;
          border: 1px solid $INPUT_BORDER;
          border-radius: 0px 0px 8px 8px;
          & > div {
            border-radius: 8px;
            color: $TEXT_TITLE !important;
            font-family: "SF Pro Text";
            font-size: 14px;
            &:hover {
              background-color: $DROPDOWNHOVER !important;
            }
          }
        }
        .map_view {
          line-height: 0px;
          iframe {
            max-height: 369px;
          }
        }
      }

      .css-166bipr-Input {
        margin: 0px;
        padding: 0px;
      }
    }

    .servicetimingWrapper {
      .timing_heading {
        ul {
          display: grid;
          grid-template-columns: 1fr 134px 134px 75px;
          margin-bottom: 8px !important;
          li {
            font-size: 16px;
            color: $TIMINGHEADING;
            font-weight: 400;
          }
        }
      }

      .timing_list {
        ul {
          display: grid;
          grid-template-columns: 1fr 134px 134px 75px;
          grid-gap: 16px;
          margin-bottom: 10px !important;
          li {
            &.list_col {
              .inputbox {
                padding: 9px 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid $INPUT_BORDER;
                border-radius: 8px;
                min-height: 48px;
                .day {
                  color: $TEXT_TITLE;
                  font-size: 16px;
                }
                .calenderContainer {
                  text-align: center;
                  width: 100%;
                  .custom-time-input {
                    background-color: transparent;
                    border: 0px;
                    color: $TEXT_TITLE;
                    padding: 0px;
                    font-size: 16px;
                  }
                }
                &.disable {
                  .custom-time-input {
                    color: $DATETEXT;
                  }
                }
              }
              &:last-child {
                .inputbox {
                  padding: 0px;
                  border: 0px;
                  .checkboxMain {
                    input {
                      &:checked {
                        + label {
                          &:after {
                            border-color: $TIMINGHEADING;
                            top: 5px;
                            width: 6px;
                            height: 13px;
                            left: 10px;
                          }
                          &::before {
                            background-color: transparent;
                          }
                        }
                      }
                    }
                    label {
                      &:before {
                        width: 26px;
                        height: 26px;
                        border: 1px solid $TIMINGHEADING;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .ContactinformationWrapper {
      margin-top: 36px;
      .form-control {
        padding: 12px 44px;
        color: $TEXT_PURPLE;
        &::placeholder {
          color: $PLACEHOLDER;
        }
      }
      .left_icon {
        i {
          color: $ICONCOLOR;
        }
      }
      .countryPhoneGroup {
        &::before {
          border: 1px solid $BORDERCOLOR;
        }
        .icon-phone {
          color: $ICONCOLOR;
        }
      }
    }
  }

  .custombtnfield {
    padding: 24px 0px 0px;
    border-top: 2px solid #07318021;
    display: flex;
    grid-gap: 16px;
    button {
      width: fit-content !important;
      min-width: 163px;
      max-height: 36px !important;
      min-height: 36px !important;
      padding: 0px !important;
      border-radius: 8px !important;
      &.outline_btn {
        border: 1px solid $TEXT_PURPLE;
        span {
          color: $TIMINGHEADING !important;
        }
      }
    }
  }
}

.add_business_modal .CustomAddBusiness {
  padding: 0px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .CustomAddBusiness {
    .add_Business {
      padding: 0 20px;

      .add_business_container {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .CustomAddBusiness {
    .add_Business {
      padding: 0 20px;

      .add_business_container {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .CustomAddBusiness {
    .add_Business {
      padding: 0 20px;

      .add_business_container {
        width: 100%;

        .add_no_business {
          font-size: $FONT_16;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .CustomAddBusiness {
    .add_Business {
      padding: 0 20px;

      .add_business_container {
        width: 100%;

        .add_no_business {
          font-size: $FONT_16;
        }
      }
    }
  }
}
