@import "../../assets/scss/variables.scss";
.ListEventMain {
	padding: 0 15px;
	width: 100%;
	float: left;
	display: inline-block;
	position: relative;

	.past_title {
		width: 100%;
		float: left;
		font-size: $FONT_20;
		line-height: 24px;
		font-weight: 600;
		font-family: $SFPROTEXT;
		color: $TEXT_SECONDARY_ONE;
		padding-top: 10px;
		padding-bottom: 10px;
		display: flex;
		margin-left: 0;
		margin-right: 0;
		justify-content: space-between;
		align-items: center;
		.totalEventTitle {
			display: flex;
			justify-content: flex-start;
			.count_title {
				float: left;
				font-size: 14px;
				color: $ICONCOLOR;
				font-family: $SFPROTEXT;
				font-weight: 400;
				line-height: 18px;
				display: flex;
				align-items: center;
			}
			.count_number {
				margin-left: 8px;
				font-size: 12px;
				line-height: 14px;
				color: $SEARCHOPTION;
				background-color: $BG_WHITE;
				padding: 4px 14px;
				border-radius: 30px;
			}
		}
		span {
			display: none;
			&.line_border {
				margin: 0 10px;
			}
		}
	}
	&.disable {
		-webkit-transition: all 2s ease-in-out;
		-moz-transition: all 2s ease-in-out;
		-o-transition: all 2s ease-in-out;
		-ms-transition: all 2s ease-in-out;
		transition: all 2s ease-in-out;
		.full_btn {
			opacity: 0.4;
			.active_bg {
				background-color: #f7f7f7 !important;
			}
			.title_left {
				.title_txt {
					color: $PLACEHOLDER;
					font-weight: 500;
				}
			}
		}
	}
	.full_btn {
		width: 100%;
		float: left;
		border: none;
		background-color: $BG_WHITE;
		box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
		border: 1px solid transparent;
		border-radius: 8px;
		margin-bottom: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		outline: 0;
		padding: 18px 16px 18px 16px;
		cursor: pointer;
		position: relative;
		// flex-wrap: wrap;
		&.list_selected_bg {
			border-color: #fbdc6e;
			background-color: $BG_WHITE;
			.title_left {
				.cricle_bg {
					.selected_bg {
						opacity: 1;
					}
				}
			}
			.active_bg {
				display: none;
			}
		}
		.active_bg {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			opacity: 0.2;
			display: none;
			border-radius: 8px;
		}
		.title_left {
			width: 43%;
			float: left;
			position: relative;
			padding-right: 10px;
			.title_row {
				width: 100%;
				float: left;
				text-align: left;
				padding: 0 0 0 0px;
				.title_txt {
					font-size: $FONT_16;
					color: $TEXT_SECONDARY_TWO;
					font-family: $SFPROTEXT;
					font-weight: 600;
					line-height: 20px;
					padding-bottom: 8px;
				}
				.location {
					display: flex;
					margin-left: 0px;
					font-size: $FONT_12;
					line-height: 14px;
					color: $TEXT_BLUE_DARK;
					padding: 0px;
					border-radius: 30px;
					.icon-pin {
						font-size: $FONT_16;
						color: $TEXT_BLUE_DARK;
						margin-right: 8px;
					}
				}
			}
		}
		.middle_part {
			width: 43%;
			float: left;
			padding-right: 10px;
			text-align: left;
			.datetime_txt {
				font-size: $FONT_14;
				line-height: 18px;
				font-weight: 400;
				font-family: $SFPROTEXT;
				color: $TEXT_SECONDARY_TWO;
				display: flex;
				align-items: center;
				.icon-calender {
					font-size: $FONT_20;
					color: $TEXT_YELLOW;
					padding-right: 10px;
				}
			}
		}
		.action_right {
			width: 14%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			z-index: 1;
			button {
				border: none;
				background-color: transparent;
				padding: 0px;
				margin: 0 12px;
				&.icon-pencil {
					color: $TEXT_SECONDARY_ONE;
					font-size: $FONT_20;
				}
				&.icon-delete {
					color: $TEXT_RED;
					font-size: $FONT_20;
				}
				&.expired_btn {
					color: $TEXT_RED;
					font-size: $FONT_12;
					line-height: 14px;
					font-weight: 400;
					font-family: $SFPROTEXT;
					width: 70px;
					border-radius: 30px;
					height: 22px;
					margin: 0px;
				}
			}
			.CustomToggleButton-container {
				margin: 0 0px 0 12px;
				.CustomToggleButton-main {
					width: 33px;
					height: 20px;
					input:checked + .slider-button:before {
						transform: translateX(14px);
						top: 0px;
					}
					.slider-button {
						&:before {
							width: 17px;
							height: 18px;
							left: 0px;
							bottom: 0;
						}
					}
				}
			}
		}
		&:hover {
			border-color: #fbdc6e;
			-webkit-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
			-moz-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
			-ms-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
			-o-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
			box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
		}
	}
}
