@import "../../assets/scss/variables.scss";

.offcanvas-header {
	text-align: left;
	justify-content: left !important;
	padding: 30px 40px 0px !important;
	.btn-close {
		left: 40px;
		position: unset;
		padding: 2px;
		top: 25px;
		width: 14px;
	}
}

.bottom-border {
	border-bottom: 1px solid $BG_LIGHTGREY;

	&:not(:first-child) {
		.icon {
			font-size: 20px;
			position: relative;
			color: $TEXT_PURPLE;
			opacity: 0.5;
			margin-right: 13px;
			position: relative;
			top: 3px;
		}
	}

	.accordion-header {
		.icon {
			top: 0;
		}
	}

	.social {
		.icon {
			margin-right: 24px;
		}
	}
}

.detialBox > div:empty {
	display: none;
}

.accordion-item {
	h2 {
		button {
			background: transparent !important;
			color: inherit !important;
			box-shadow: none !important;
		}
	}
}

.accordion-button:not(.collapsed)::after {
	background-image: var(--bs-accordion-btn-icon);
	transform: var(--bs-accordion-btn-icon-transform);
}

.offcanvas {
	width: 475px !important;
	padding: 0;

	.offcanvas-body {
		position: relative;
		padding: 0 40px;

		.detialBox {
			.time > * {
				display: inline-block;
			}

			.accordion-body {
				div {
					span:nth-child(2) {
						width: calc(100% - 50px);
					}
				}
			}
		}

		span {
			margin-right: 5px;
		}

		span.isActive {
			color: green;
		}
	}

	.eventTop {
		h2 {
			font-weight: 600;
			font-size: 24px !important;
			line-height: 29px;
			color: $TEXT_TITLE;
			margin-top: 16px;
			margin-bottom: 10px !important;
		}

		.topLine {
			display: flex;
			align-items: center;
			position: relative;
			grid-gap: 12px;

			& > .icon {
				font-size: 20px;
			}

			.distance {
				margin-top: 0;
				position: relative;
			}

			.onlineStatus {
				padding: 8px 12px;
				border-radius: 100px;
				display: flex;
				align-items: center;
				font-size: 14px;
				font-weight: 400;
				line-height: normal;
				color: $BG_BLUE;
				background: $SUCESSCOLOR;
			}

			span.category {
				margin-left: 3px;
			}
		}

		.address {
			font-size: 14px;
			line-height: 17px;
			color: $TEXT_TITLE;
			display: flex;
			align-items: inherit;
			grid-gap: 9px;

			span {
				margin-right: 5px;
				font-size: $FONT_20;
				color: $BG_SECONDARY_TWO;
			}
		}

		.btn-wrap {
			grid-gap: 8px;
			display: flex;
			.btn {
				margin: 0px !important;
				&:nth-child(1) {
					padding: 12px 24px;
					display: flex;
					align-items: center;
					span {
						margin: 0px;
					}
				}
			}
			.buttonJoinNow {
				display: flex;
				align-items: center;
				.icon {
					font-size: 16px;
				}
			}
		}

		.TownList {
			ul {
				display: flex;
				grid-gap: 10px;
				margin-top: 3px !important;
				flex-wrap: wrap;
				li {
					display: flex;
					align-items: center;
					grid-gap: 10px;
					font-size: 12px;
					color: $ICON_ORANGE;
					font-weight: 400;
					&:after {
						content: "";
						width: 6px;
						height: 6px;
						background: #d9d9d9;
						display: flex;
						border-radius: 100px;
					}
					&:last-child {
						&:after {
							display: none;
						}
					}
				}
			}
		}

		.description {
			font-weight: 400;
			font-size: 14px;
			margin-top: 16px;
			color: $ICONCOLOR;
		}
	}
}

.detialBox {
	& > div {
		padding: 24px 0;
	}

	.accordion-header {
		button {
			padding: 0;
			background: transparent !important;
			color: inherit !important;
			box-shadow: none !important;
			font-size: 14px;

			div {
				color: $TEXT_TITLE;
				font-size: 14px;
				font-weight: 400;
				span {
					width: auto;
					display: inline-block;
					color: $TEXT_TITLE;
				}
			}

			span:last-child {
				margin-left: 10px;
				color: $TEXT_YELLOW;
			}
		}
	}

	.accordion-body {
		padding-left: 32px;
		padding-bottom: 0;
		padding-top: 11px;

		div {
			font-size: 14px;
			margin-bottom: 11px;
			color: $TEXT_TITLE;
			font-weight: 400;

			span {
				width: 40px;
				display: inline-block;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.phone,
	.email,
	.website,
	.social,
	.time {
		color: $TEXT_TITLE;
		font-size: 14px;
		font-weight: 400;
	}

	.social {
		display: flex;
		grid-gap: 24px;
		.icon {
			font-size: 22px !important;
			margin: 0px;
		}
	}
}

.pins {
	margin: 24px 0px 0px;
	display: flex;
	grid-gap: 10px !important;
	flex-wrap: wrap;

	div {
		padding: 8px 16px;
		line-height: 1;
		border: 1px solid $BG_LIGHTGREY;
		display: inline-block;
		font-size: 14px;
		border-radius: 50px;
		margin: 0px !important;

		span {
			font-size: 18px;
			position: relative;
		}

		.nameMap {
			font-size: 15px;
			display: flex;
			margin: 0px !important;
			align-items: center;
			grid-gap: 4px;
			&:before {
				font-size: 18px;
			}

			.text_name {
				margin: 0;
				font-family: $SFPROTEXT;
				color: $TEXT_TITLE;
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
	&:empty {
		display: none;
	}
}

.showViewData {
	width: 100%;
	height: auto;
	position: fixed;
	top: -100%;
	right: -100%;
	pointer-events: none;
	z-index: -1;
	font-family: Arial, Helvetica, sans-serif;
	padding: 0 28px 0 20px;

	.titleText {
		.service {
			color: $TEXT_TITLE;
			font-weight: 600;
			font-size: 32px;
			padding: 30px 30px 30px 30px;
		}
	}
	.showTable {
		width: 100%;
		height: auto;

		.secondTable {
			width: 100%;
			height: auto;
			margin-left: 15px;
			margin-right: 15px;
			margin-bottom: 20px;

			.categoryList {
				vertical-align: top;
				margin-bottom: 24px;
				padding: 0 15px;
				width: 25%;
				word-break: break-all;

				.category {
					margin-top: 10px;
					color: $ICON_ORANGE;
					font-size: 24px;
					padding-bottom: 10px;
					font-weight: 400;
				}

				.name {
					margin-top: 10px;
					color: $TEXT_TITLE;
					font-size: 32px;
					padding-bottom: 10px;
					font-weight: 600;
				}

				.description {
					margin-top: 10px;
					color: $ICONCOLOR;
					word-break: break-word;
					font-size: 22px;
					padding-bottom: 16px;
					font-weight: 400;
					max-width: 400px;
				}

				.title {
					font-size: 24px;
					color: $TEXT_TITLE;
					font-weight: 400;
					padding-bottom: 10px;
				}
				.TownList {
					margin-top: 10px;
					ul {
						display: flex;
						grid-gap: 10px;
						flex-wrap: wrap;
						li {
							display: flex;
							align-items: center;
							grid-gap: 10px;
							font-size: 18px;
							color: $NAVLINK;
							font-weight: 400;
						}
					}
				}
			}

			.categoryList1 {
				padding-left: 20px;
				vertical-align: top;
				margin-bottom: 24px;

				.serviceTime {
					font-size: 24px;
					font-weight: 700;
					color: $TEXT_TITLE;
					padding-bottom: 10px;
				}

				.serviceData {
					font-size: 24px;
					font-weight: 400;
					color: $NAVLINK;
					padding-bottom: 10px;
					width: calc(100% - 50px);

					.isActive {
						margin-left: 5px;
						color: $ICON_ORANGE;
					}

					.serviceTiming {
						padding: 15px 0px;

						.serviceDay {
							padding-right: 10px;
						}

						.isActive.serviceDay {
							padding-left: 10px;
						}
					}
				}

				.eventDataStyle {
					display: flex;
					font-size: 24px;
					color: $NAVLINK;

					.icon.icon-calendar {
						margin-top: 3px;
						margin-right: 13px;
					}
					div {
						.isActive {
							color: green;
							margin-left: 5px;
						}
					}
				}
			}

			.categoryList2 {
				vertical-align: top;
				margin-bottom: 24px;

				.eventTime {
					font-size: 24px;
					font-weight: 700;
					color: $TEXT_TITLE;
					padding-bottom: 10px;
				}
				.eventLocation {
					font-size: 24px;
					font-weight: 700;
					color: $TEXT_TITLE;
					padding-bottom: 10px;
				}
				.eventLocationData {
					font-size: 22px;
					font-weight: 400;
					color: $NAVLINK;
					padding-bottom: 10px;
				}
				.eventData {
					font-size: 24px;
					font-weight: 400;
					color: $TEXT_TITLE;
					padding-bottom: 10px;
					margin-top: 10px;

					.mapName {
						margin-top: 10px !important;
					}
				}
			}

			.categoryList3 {
				vertical-align: top;
				background: rgba(236, 236, 236, 0.25);
				margin-bottom: 24px;
				max-width: 400px;
				padding: 15px;
				width: 25%;
				word-break: break-all;

				.location {
					font-size: 24px;
					color: $TEXT_TITLE;
					font-weight: 400;
					padding-bottom: 20px;

					span {
						font-weight: 700;
						padding-right: 5px;
					}
				}
			}

			.categoryList3.locationTime {
				max-width: 400px;
			}
		}
	}
}
