@import "../../assets/scss/variables.scss";

.notFoundScreen {
  width: 100%;
  height: 100vh;
  float: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .cardBox {
    display: block;
    text-align: center;

    .brand-logo {
      width: 200px;
      display: block;
      margin: 0 auto;
    }

    .title {
      margin: 0 0 20px 0;
      font-size: 80px;
      font-family: $SFPROTEXT;
      color: $TEXT_BLACK;
    }

    .sub-title {
      margin: 0 0 10px 0;
      color: $TEXT_BLACK;
      font-size: $FONT_18;
    }

    .txt {
      margin: 0;
      color: $TEXT_BLACK;
      font-size: $FONT_18;
    }
  }
}
