@import "../../assets/scss/variables.scss";

.googleDropdown {
	width: 100%;
	float: left;
	&.error {
		border-radius: 8px;
		border: 1px solid red;
	}
	#react-select-2-placeholder {
		color: $PLACEHOLDER;
		// &::after {
		// 	content: "*";
		// 	color: red;
		// }
	}

	#react-select-6-placeholder {
		color: $PLACEHOLDER;
		// &::after {
		// 	content: "*";
		// 	color: red;
		// }
	}
	.react-select-3-input {
		color: $TEXT_SECONDARY_TWO;
	}
	.css-1pahdxg-control {
		&:hover {
			border-color: $INPUT_BORDER;
		}
	}
	.css-1pahdxg-control,
	.css-yk16xz-control {
		background: transparent;
		font-family: $SFPROTEXT;
		color: $TEXT_RED;
		font-size: $FONT_14;
		padding: 8.5px 14px;
		padding-right: 0;
		height: auto;
		width: 100%;
		border-radius: 8px;
		border: 1px solid $INPUT_BORDER;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		box-shadow: none;
		.css-1hwfws3 {
			padding: 0;
			.css-1uccc91-singleValue {
				color: $TEXT_SECONDARY_TWO;
			}
		}
		.css-1wy0on6 {
			display: none;
		}
	}
	.css-26l3qy-menu {
		top: 100%;
		background-color: $BG_WHITE;
		border-radius: 10px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
		-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
		-moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
		-ms-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
		-o-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
		margin-bottom: 8px;
		margin-top: 0;
		position: absolute;
		width: 100%;
		z-index: 1;
		box-sizing: border-box;
		overflow: hidden;

		.css-11unzgr {
			padding: 0;

			.css-1n7v3ny-option {
				position: relative;
				padding: 8px 14px 8px 30px;
				font-family: $SFPROTEXT;
				font-size: $FONT_14;
				color: $TEXT_WHITE;
				background-color: $BG_SECONDARY_ONE;

				&::before {
					font-family: "angus_icon";
					position: absolute;
					content: "\e904";
					left: 10px;
					font-size: $FONT_12;
					color: $TEXT_WHITE;
					margin-top: 3px;
				}
			}
			.css-yt9ioa-option {
				position: relative;
				padding: 8px 14px 8px 30px;
				font-family: $SFPROTEXT;
				font-size: $FONT_14;
				color: $TEXT_SECONDARY_TWO;
				&::before {
					font-family: "angus_icon";
					position: absolute;
					content: "\e904";
					left: 10px;
					font-size: $FONT_12;
					color: $TEXT_SECONDARY_TWO;
					margin-top: 3px;
				}
			}
			.css-9gakcf-option {
				position: relative;
				padding: 8px 14px 8px 30px;
				font-family: $SFPROTEXT;
				font-size: $FONT_14;
				color: $TEXT_WHITE;
				background-color: $BG_SECONDARY_ONE;

				&::before {
					font-family: "angus_icon";
					position: absolute;
					content: "\e904";
					left: 10px;
					font-size: $FONT_12;
					color: $TEXT_WHITE;
					margin-top: 3px;
				}
			}
		}
	}
	.css-26l3qy-menu::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	.css-26l3qy-menu::-webkit-scrollbar-thumb {
		background-color: $SCROLLCOLOR;
		border-radius: 4px;
		-webkit-box-shadow: 0 0 1px $BOXSHADOWColor;
	}
	.css-26l3qy-menu::-webkit-scrollbar {
		height: 6px;
		width: 4px;
	}
	.map {
		display: block;
		margin: 20px 0;
	}
}
