@import "../../assets/scss/variables.scss";

.CustomSideBar {
  overflow-y: hidden !important;
  position: fixed;
  top: 0px;
  max-width: 475px;
  width: 100%;
  right: 0;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  z-index: 11;
  background: $BG_WHITE;
  height: 100%;
  &.show {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
  }
  .sidebarInner {
    border-radius: 0;
    position: relative;
    .headerBar {
      width: 100%;
      float: left;
      background: $BG_SECONDARY_ONE;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 16px 20px;
      justify-content: space-between;
      min-height: 52px;
      max-height: 52px;
      .title {
        font-size: $FONT_16;
        line-height: 20px;
        font-weight: 600;
        font-family: $SFPROTEXT;
        color: $TEXT_WHITE;
      }
      .iconClose {
        cursor: pointer;
        text-decoration: none !important;
        .icon-close {
          color: $TEXT_WHITE;
          font-size: $FONT_14;
        }
      }
      .icon-back {
        color: $TEXT_WHITE;
        font-size: $FONT_16;
        border: none;
        padding: 0px;
        margin-right: 14px;
        background-color: transparent;
        display: none;
        transform: rotate(180deg);
      }
    }
    .action_header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: $BG_LIGHTGREY;
      padding: 16px 16px;
      button {
        border: none;
        background-color: transparent;
        padding: 0px;
        margin: 0 12px;
        &.icon-pencil {
          color: $TEXT_SECONDARY_ONE;
          font-size: $FONT_20;
        }
        &.icon-delete {
          color: $TEXT_RED;
          font-size: $FONT_20;
        }
      }
      .CustomToggleButton-container {
        margin: 0 0px 0 12px;
        .CustomToggleButton-main {
          width: 33px;
          height: 20px;
          input:checked + .slider-button:before {
            transform: translateX(14px);
            top: 0px;
          }
          .slider-button {
            &:before {
              width: 17px;
              height: 18px;
              left: 0px;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1785px) {
  .headerAdmin .CustomSideBar {
    height: calc(100% - 84px);
    top: 84px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1872px) {
  .CustomSideBar {
    .sidebarInner {
      .headerBar {
        .icon-back {
          display: block;
        }
      }
    }
  }
  .changePasswordModel span.title {
	margin: auto;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .CustomSideBar {
    .sidebarInner {
      .headerBar {
        .icon-back {
          display: block;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .CustomSideBar {
    .sidebarInner {
      .headerBar {
        .icon-back {
          display: block;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .CustomSideBar {
    max-width: 100%;
    .sidebarInner {
      .headerBar {
        .icon-back {
          display: block;
        }
      }
    }
  }
}
