@import "../../assets/scss/variables.scss";

.CustomSelectPicker {
  width: 100%;
  float: left;

  .CustomSelectPickerinput{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border: 1px solid $BORDERCOLOR;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  .dropdownColor{
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.5s ease-out;
    -moz-transition: max-height 0.5s ease-out; 
    -ms-transition: max-height 0.5s ease-out;
    -o-transition: max-height 0.5s ease-out;
    transition: max-height 0.5s ease-out;
    &.show{
      max-height: 262px;
      -webkit-transition: max-height 0.5s ease-out;
      -moz-transition: max-height 0.5s ease-out; 
      -ms-transition: max-height 0.5s ease-out;
      -o-transition: max-height 0.5s ease-out;
      transition: max-height 0.5s ease-out;
    }
  }
  .circle-picker {
    width: 100% !important;
    padding: 20px 4px;
    span{
      width: 20%;
    }
  }
}

