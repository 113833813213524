@import "./assets/scss/variables.scss";

// body {
// 	margin: 0;
// 	padding: 0;
// 	font-family: $SFPROTEXT !important;
// 	background-color: $BG_GREY2;
// 	color: $TEXT_BLACK;
// 	height: 100vh;
// 	-moz-osx-font-smoothing: grayscale;
// 	-webkit-font-smoothing: antialiased;
// 	-webkit-overflow-scrolling: touch;
// }

input::-ms-reveal,
input::-ms-clear {
	display: none;
}

* {
	box-sizing: border-box;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("./assets/fonts/SFProText-Bold.woff2") format("woff2"),
		url("./assets/fonts/SFProText-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("./assets/fonts/SFProText-Heavy.woff2") format("woff2"),
		url("./assets/fonts/SFProText-Heavy.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("./assets/fonts/SFProText-Light.woff2") format("woff2"),
		url("./assets/fonts/SFProText-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("./assets/fonts/SFProText-Medium.woff2") format("woff2"),
		url("./assets/fonts/SFProText-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("./assets/fonts/SFProText-RegularItalic.woff2") format("woff2"),
		url("./assets/fonts/SFProText-RegularItalic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("./assets/fonts/SFProText-Regular.woff2") format("woff2"),
		url("./assets/fonts/SFProText-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SF Pro Text";
	src: url("./assets/fonts/SFProText-Semibold.woff2") format("woff2"),
		url("./assets/fonts/SFProText-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

.modal-backdrop.show {
	opacity: 0.2;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px transparent inset; /* Change the color to your own background color */
	-webkit-text-fill-color: $INPUTFILLCOLOR;
}

input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 50px transparent inset; /*your box-shadow*/
	-webkit-text-fill-color: $INPUTFILLCOLOR;
}
.countryPhoneGroup {
	float: left;
	width: 100%;
	position: relative;
	z-index: 0;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	&::before {
		width: 100%;
		height: 47px;
		top: -1px;
		position: absolute;
		left: 1px;
		content: "";
		border-radius: 8px;
		border: 1px solid $BORDERCOLOR;
	}
	.icon-phone {
		position: absolute;
		font-size: 20px;
		cursor: pointer;
		color: $PHONEICONCOLOR;
		padding: 0 10px;
		top: 13px;
		left: 0px;
	}
	.react-tel-input {
		position: absolute;
		left: 40px;
		top: 0px;
		width: 50px;
		outline: none;
		z-index: 1;
		.country-list {
			left: -38px;
			.search {
				padding: 10px 10px 6px 10px;
				.search-box {
					margin-left: 0px;
					width: 100%;
					font-family: $SFPROTEXT;
					font-size: 14px;
					padding-top: 8px;
					padding-bottom: 6px;
				}
			}
			.country {
				font-family: $SFPROTEXT;
				.country-name {
					color: $SEARCHOPTION;
				}
			}
		}
		.special-label {
			display: none;
		}

		.country-list {
			.country {
				text-align: left;
			}
		}

		.selected-flag {
			background: transparent !important;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			padding: 0px;
			.flag {
				opacity: 0;
			}
		}

		.flag-dropdown {
			border: none;
			background: transparent !important;
		}

		.form-control {
			width: 50px;
			height: 44px;
			padding: 0px !important ;
			text-align: left;
			border: none;
			background: transparent;
			outline: none;
			box-shadow: none;
			font-size: 14px;
			font-family: $SFPROTEXT;
			&::placeholder {
				color: $PLACEHOLDER;
				opacity: 1;
			}

			&:-ms-input-placeholder {
				color: $PLACEHOLDER;
			}

			&::-ms-input-placeholder {
				color: $PLACEHOLDER;
			}
		}
	}

	.TextFieldNoRequired {
		padding-left: 90px;
		margin-bottom: 0px;
		.form-control {
			padding-left: 0px !important;
			border: none;
			background-color: $BG_WHITE;
			border-radius: 0px;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}
		.errorMsg {
			position: relative;
			width: calc(100% + 90px);
			left: -90px;
		}
	}
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background-color: $SEARCHOPTION !important;
	color: white;
	font-weight: bold;
}
