// Background Colors
$BG_Body:#F3F3F6;
$BG_BLACK: #000000;
$BG_PURPLE: #073180;
$BG_BLACK_DARK: #252525;
$BG_LIGHTGREY: #ececec;
$BG_GREY:#585858;
$BG_BLUE:#3a4468;
$BG_GRET_LIGHT:#E5E5E6;
$BG_BLUE_LIGHT:#9EA4B9;
$BG_GREY_LIGHT:#ECECEC;
$BG_WHITE:#ffffff;
$BG_YELLOW: #FEC242;
$BG_SECONDARY_ONE:#073180;
$BG_SECONDARY_TWO:#3A4468;
$BG_BLUE_DARK:#8289A2;
$BG_RED: #FF4539;
$BG_GREEN: #32D74C;
$BG_GREEN_LIGHT:#79CA5B;
$BG_GREEN_DARK:#34C759;
$BG_YELLOW_LIGHT: #FFD60A;
$BG_DISABLE:#DDDDDD;
$BG_YELLOW2: #ffd60a;
$BG_OPACITY:#F2EEF7;
$BG_BOX:#07318008;
$BG_GREY2: #f3f3f6;
$BORDER_BOTTOM: #ececec;
$BG_YELLOW_LIGHT: #fff9ec;
$CONTACTBOXCOLOR : #320e3b08;
$DATETEXT: #bbbbbb;
$BOXSHADOW: #11111114;
$NOCATEGORYTEXT: #808080;
$ICONCOLOR: #838aa3;
$INPUTDISABLE: #8b959e;
$INPUTFILLCOLOR: #333;
$DROPDOWNHOVER: #0731800d;
$TIMINGHEADING: #8289a2;
$BUTTONCOLOR: #2e3a59;
$SCROLLCOLOR: #c7c9cb;
$BOXSHADOWColor: #1111110d;
$NAVLINK: #838AA3;
$UPLOADTEXT: #6D96FF;



// icon colors

$ICON_ORANGE: #ff9800;
$ICON_RED: #e91e63;
$ICON_GREY: #bdb9b9;
$ICON_VIOLET: #3f51b5;
$ICON_BLUE: #2196f3;
$ICON_YELLOW: #f5d52a;
$ICON_GREEN: #009688;


// Text Colors
$TEXT_BLACK: #000000;
$TEXT_BLACK_DARK: #252525;
$TEXT_PURPLE: #073180;
$TEXT_GREY: #8289A2;
$TEXT_TITLE: #3a4468;
$TEXT_GREY_PLACEHOLDER:#585858;
$TEXT_BLUE_LIGHT:#9EA4B9;
$TEXT_GREY_LIGHT:#ECECEC;
$TEXT_WHITE:#ffffff;
$TEXT_YELLOW: #FEC242;
$TEXT_SECONDARY_ONE:#073180;
$TEXT_SECONDARY_TWO:#3A4468;
$TEXT_BLUE_DARK:#8289A2;
$TEXT_RED: #FF4539;
$TEXT_GREEN: #32D74C;
$TEXT_GREEN_LIGHT:#79CA5B;
$TEXT_GREEN_DARK:#34C759;
$TEXT_YELLOW_LIGHT: #FFD60A;
$PLACEHOLDER: #BBBBBB;
$TEXT_BLUE_DARK_LIGHT:#1F2F47;
$SUCESSCOLOR: #B0D682;
$TEXT_GREY_COLOR: #7F7F7F;
$PHONEICONCOLOR: #a99aad;
$SEARCHOPTION: #320e3b;
$SEARCHTEXT: #c7d1e3;
$BORDERCOLOR: #320e3b33;
$DAYTEXTCOLOR: #98A9CA;
$ICONCOLOR: #9CADCC;
$BORDERCOLOR: #CDD6E6;
$TIMECOLORTEXT: #454545;
$TIMEBORDER: #D6D9DA;
$avtarbg: #E9E8ED;
$UPLOADBGCOLOR: #320e3b0d;
$SCROLLTRACK: #f1f1f1;
$DROPDOWNBORDER: #ebebeb;

// border-color
$INPUT_BORDER:#0731804d;
$LINE_BORDER:#0731804d;
$LINE_BORDER_BOTTOM:#0731804d;
$YELLOW_LIGHT: #FFF9EC;
$BORDERCOLOR: #c8d1e2;


// Font Size
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_15: 15px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_30: 30px;
$FONT_36: 36px;
$FONT_48: 48px;
$FONT_60: 60px;
$FONT_96: 96px;


// Font Family
$SFPROTEXT: 'SF Pro Text';

$SFPRODISPLAY: SF Pro Display;