@import "../../assets/scss/variables.scss";

.header {
  max-width:calc( 100% - 317px);
  width: 100%;
  left: 317px;
  top: 0;
  position: fixed;
  z-index: 9;
  box-shadow: none;
  background-color: $BG_Body;
  transition: 0.3s all;
  .navbar {
    display: flex;
    align-items: center;
    padding: 0;
    width: 100%;
    float: left;

    .navbar-brand {
      padding: 16px 0;
      margin: 0;
      display: block;
      width: 100%;
      float: left;
      text-align: center;
      min-height: 84px;

      &:focus {
        outline: none;
      }

      img {
        position: relative;
        width: 125px;
        height: auto;
      }
    }

    .navbar-collapse {
      float: left;
      padding:0px;
      .navbar-nav {
        flex-direction: row;
        width: 100%;
        float: left;
        padding: 0 10px;
        .nav-link {
          font-family: $SFPROTEXT;
          font-size: $FONT_16;
          color:$TEXT_BLUE_DARK;
          font-weight: 600;
          line-height: 20px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 46px;
          margin: 20px 10px;
          width: 296px;
          float: left;
          border-radius: 8px;
          background-color: $BG_WHITE;
          &.active,
          &:hover {
            background-color: $BG_WHITE;
            color: $TEXT_SECONDARY_TWO;
            -webkit-box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.12);
            -moz-box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.12);
            -ms-box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.12);
            -o-box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.12);
            box-shadow: 0px 24px 48px rgba(84, 95, 122, 0.12);
            .icon{
              color: $TEXT_YELLOW;
            }
          }
          i {
            margin-right: 16px;
            font-size: 32px;
            color:$TEXT_BLUE_DARK;
          }
        }
      }
    }

    .profiledropdown {
      float: right;
      padding-right: 20px;
      padding-top: 0px;
      display: flex;
      .profilebtn{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .avtar {
        border-radius: 50%;
        padding: 0px;
        color: $TEXT_BLACK;
        font-family: $SFPROTEXT;
        font-size: $FONT_14;
        display: flex;
        align-items: center;
        img {
          border: 2px solid $BG_WHITE;
          width: 48px;
          height: 48px;
          border-radius:50%;
          object-fit: cover;
          background-color: $avtarbg;
          box-shadow: 0px 4px 4px $avtarbg;
          -webkit-box-shadow: 0px 4px 4px $avtarbg;
          -moz-box-shadow: 0px 4px 4px $avtarbg;
          -ms-box-shadow: 0px 4px 4px $avtarbg;
          -o-box-shadow: 0px 4px 4px $avtarbg;
        }
        .sortName {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: #E7E9F0;
          font-family: $SFPROTEXT;
          color:$TEXT_SECONDARY_ONE;
          text-transform: uppercase;
          font-size: $FONT_14;
          line-height: 18px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .app_menu{
        display: flex;
        align-items: center;
        line-height: 18px;
        margin-right: 35px;
        text-align: left;
        font-family: $SFPROTEXT;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px;
        &::after{
          content: "";
          width: 1px;
          height: 16px;
          background-color: $PLACEHOLDER;
          left:18px;
          position: relative;
        }
        .txt {
          font-size: $FONT_12;
          color: $TEXT_SECONDARY_TWO;
          line-height: 18px;
        }
        .icon-setting{
          font-size: $FONT_20;
          color: $TEXT_BLUE_DARK;
          margin-left: 10px;
        }
      }
      .avtar-name {
        display: flex;
        align-items: center;
        line-height: 18px;
        margin-right: 16px;
        text-align: left;
        font-family: $SFPROTEXT;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px;
        p {
          font-size: $FONT_14;
          color: $TEXT_BLACK;
          margin: 0;
        }
        span {
          font-size: $FONT_12;
          color: $TEXT_SECONDARY_TWO;
          word-break: break-word;
        }
        .icon-arrow-right{
          font-size: $FONT_10;
          color: $TEXT_BLUE_DARK;
          margin-left: 8px;
        }
      }
    }
  }
}


@media only screen and (max-width:1444px){
  .header{
    max-width: calc( 100% - 317px);
  }
}

@media only screen and (min-width:1201px) and (max-width:1366px){
  .header{
    .navbar{
      .navbar-collapse{
        .navbar-nav{
          .nav-link {
            font-size: 16px;
            line-height: 20px;
            justify-content: center;
            padding: 10px 46px;
            margin: 20px 10px;
            width: 220px;
          }
        }
      }
    }
  }    
}

@media only screen and (min-width:1025px) and (max-width:1200px){
  .header{
    .navbar{
      .navbar-collapse{
        .navbar-nav{
          .nav-link {
            font-size: 14px;
            line-height: 20px;
            justify-content: flex-start;
            padding: 10px 16px;
            margin: 20px 10px;
            width: 150px;
            i {
              margin-right: 10px;
              font-size: 24px;
              color: $NAVLINK;
          }
          }
        }
      }
    }
  }    
}

@media only screen and (min-width:992px) and (max-width:1024px){
  .header{
    .navbar{
      .navbar-collapse{
        .navbar-nav{
          padding-right: 0px;
          .nav-link {
            font-size: 14px;
            line-height: 20px;
            justify-content: flex-start;
            padding: 10px 16px;
            margin: 20px 10px;
            width: 140px;
            i {
              margin-right: 10px;
              font-size: 24px;
              color: $NAVLINK;
          }
          }
        }
      }
    }
  }    
}

@media only screen and (min-width:768px) and (max-width:991px){
  .header{
    max-width: 100%;
    left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $BG_SECONDARY_TWO;
    .navbar{
      .navbar-collapse{
        .navbar-nav{
          padding-left: 110px;
          justify-content: center;
          .nav-link {
            font-size: 14px;
            line-height: 20px;
            justify-content: flex-start;
            padding: 10px 16px;
            margin: 20px 10px;
            width: 150px;
            i {
              margin-right: 10px;
              font-size: 24px;
              color: $NAVLINK;
          }
          }
        }
      }
      .profiledropdown{
        flex-direction: column;
        position: relative;
        padding-right: 0px;
        .profilebtn{
          flex-direction: column;
          align-items: flex-end;
          .app_menu{
            margin-right: 0px;
            .txt{
              color: $TEXT_WHITE;
            }
            .icon-setting{
              color: $TEXT_WHITE;
            }
            &::after{
              display: none;
            }
          }
          .avtar-name{
            margin-right: 30px;
            margin-top: 20px;
            span{
              color: $TEXT_WHITE
            }
            .icon-arrow-right{
              color: $TEXT_WHITE
            }
          }
        }
        .avtar{
          position: absolute;
          right: 0px;
          bottom: -2px;
          .sortName{
            width: 24px;
            height: 24px;
            font-size: 10px;
            font-weight: 600;
          }
        }
      }
    }
  }    
}

