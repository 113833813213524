@import "../../assets/scss/variables.scss";

.CustomAddEvents {
  width: 100%;
  float: left;

  .add_Business {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;

    .add_business_container {
      width: 771px;
      float: left;
      padding-bottom: 20px;

      .add_no_business {
        width: 100%;
        padding: 20px 0 16px;
        font-family: $SFPROTEXT;
        font-size: $FONT_16;
        font-weight: 400;
        line-height: 21px;
        color: $TEXT_GREY_COLOR;
        text-align: left;
      }

      .add_business_card {
        width: 100%;
        float: left;
        background-color: $BG_WHITE;
        border-radius: 8px;
        padding: 0px 65px 0px 65px;

        .form-control {
          padding-right: 16px;
        }

        .padding_inner {
          width: 100%;
          float: left;
          padding-left: 15px;
          padding-right: 15px;
          position: relative;
          z-index: 1;

          .discription_txt {
            width: 100%;
            position: relative;

            .form-control {
              padding-right: 16px;
              height: 96px;
              resize: none;
              vertical-align: top;
            }

            .textarea_count {
              position: absolute;
              right: 16px;
              top: 130px;
              font-size: $FONT_14;
              line-height: 24px;
              font-weight: 400;
              font-family: $SFPROTEXT;
              text-align: right;
              color: $PLACEHOLDER;
            }
          }

          .input_row {
            width: 100%;
            float: left;
            position: relative;

            .cricle_view {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              margin-right: 10px;
              float: left;
            }

            .customDropdown {
              .selected {
                &.dropdown {
                  .btn-primary {
                    background-color: transparent !important;
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-left: 40px;
                  }
                }
              }

              .dropdown {
                .btn-primary {
                  background-color: $BG_WHITE !important;
                }

                .dropdown-menu {
                  .dropdownData {
                    .dropdown-item {
                      padding: 12px 14px;
                    }
                  }
                }
              }
            }
          }

          .check_row {
            width: 100%;
            float: left;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .checkboxMain {
              input:checked + label::before {
                border-color: $TEXT_BLUE_DARK;
                border-width: 1px;
                border-radius: 2px;
                background: transparent;
              }

              input:checked + label:after {
                border-color: $TEXT_BLUE_DARK;
                top: 4px;
                left: 6px;
              }

              label {
                color: $TEXT_BLUE_DARK;

                &:before {
                  border-color: $TEXT_BLUE_DARK;
                  border-width: 1px;
                  border-radius: 2px;
                }
              }
            }
          }

          .address_part {
            width: 100%;
            float: left;

            &.disable {
              .textField {
                opacity: 0.4;

                .form-control {
                  &:disabled {
                    background: $BG_WHITE;
                    pointer-events: none;
                  }
                }
              }
            }
          }
        }

        .add_business_title {
          width: 100%;
          float: left;
          text-align: center;
          font-size: $FONT_20;
          line-height: 24px;
          font-weight: 600;
          font-family: $SFPROTEXT;
          color: $TEXT_SECONDARY_ONE;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 16px;
          padding-bottom: 24px;
        }

        .labelTitle {
          font-size: $FONT_18;
          font-family: $SFPROTEXT;
          font-weight: 400;
          line-height: 22px;
          color: $TEXT_SECONDARY_ONE;
          padding-bottom: 8px;

          &.title_top {
            padding-top: 20px;
          }
        }

        .location_part {
          width: 100%;
          float: left;
          padding: 0 0 16px;
          margin-bottom: 36px;

          &.disable {
            .googleDropdown {
              opacity: 0.4;
              pointer-events: none;
            }
          }

          .googleDropdown .css-1pahdxg-control,
          .googleDropdown .css-yk16xz-control {
            border-radius: 8px 8px 0px 0px;
            border-bottom: none;
          }

          .map_view {
            width: 100%;
            float: left;
            padding: 0px 0 0;
            border: 1px solid $INPUT_BORDER;
            border-radius: 0px 0px 8px 8px;
            overflow: hidden;

            .map {
              float: left;
              border-radius: 0px;
            }
          }
        }

        .businesstiming {
          width: 100%;
          float: left;
          padding-bottom: 16px;
          padding-top: 16px;

          .timing_heading {
            width: 100%;
            float: left;
            padding: 0 0 8px 0;

            ul {
              margin: 0px;
              padding: 0px;
              list-style: none;

              li {
                float: left;
                width: 29%;
                font-size: $FONT_14;
                line-height: 18px;
                color: $TEXT_BLUE_DARK;
                font-family: $SFPROTEXT;

                &:last-child {
                  width: 13%;
                  padding-left: 8px;
                }
              }
            }
          }

          .timing_list {
            width: 100%;
            float: left;

            ul {
              margin: 0px;
              padding: 0 0 8px;
              list-style: none;
              float: left;
              width: 100%;

              &:last-child {
                padding-bottom: 0px;
              }

              .list_col {
                float: left;
                width: 29%;
                padding-right: 8px;

                &:nth-child(1) {
                  padding-right: 16px;

                  .inputbox {
                    padding: 13px 8px;
                  }
                }

                &:nth-child(2) {
                  .inputbox {
                    padding-bottom: 6px;
                  }
                }

                &:nth-child(3) {
                  .inputbox {
                    padding-bottom: 6px;
                  }
                }

                &:nth-child(4) {
                  padding-left: 8px;

                  .inputbox {
                    padding-left: 0px;
                    padding-right: 0px;
                  }
                }

                .inputbox {
                  display: flex;
                  border: 1px solid $INPUT_BORDER;
                  border-radius: 8px;
                  padding: 11px 8px;
                  align-items: center;
                  justify-content: space-between;

                  &.disable {
                    opacity: 0.3;
                  }

                  .day {
                    font-size: $FONT_16;
                    line-height: 18px;
                    color: $TEXT_SECONDARY_TWO;
                    font-family: $SFPROTEXT;
                  }

                  .calenderContainer {
                    width: 100%;

                    .react-datepicker {
                      width: 100%;
                    }

                    .custom-time-input {
                      width: 100%;
                      float: left;
                      padding: 0px;
                      border: none;
                      text-align: left;
                      background-color: transparent;
                      display: flex;
                      font-size: $FONT_18;
                      line-height: 21px;
                      color: $TEXT_SECONDARY_TWO;
                      font-family: $SFPROTEXT;
                      font-weight: 400;
                      text-transform: lowercase;
                      position: relative;

                      .custom-date-placeholder {
                        font-size: $FONT_18;
                        line-height: 21px;
                        color: $PLACEHOLDER;

                        &::after {
                          content: "\e906";
                          font-family: "angus_icon";
                          font-size: 20px;
                          color: $TEXT_BLUE_DARK;
                          opacity: 0.5;
                          position: absolute;
                          right: 0px;
                          top: 0px;
                        }
                      }
                    }
                  }

                  .checkboxMain {
                    label {
                      &:before {
                        width: 26px;
                        height: 26px;
                        border: 1px solid $TEXT_BLUE_DARK;
                        padding: 2px;
                        margin-right: 0px;
                      }
                    }

                    input:checked + label {
                      &::before {
                        background-color: transparent;
                      }

                      &:after {
                        content: "\e90f";
                        font-family: "angus_icon";
                        border: none;
                        transform: none;
                        width: auto;
                        height: auto;
                        top: 3px;
                        left: 8px;
                        color: $TEXT_BLUE_DARK;
                        font-size: $FONT_12;
                      }
                    }
                  }

                  .CustomToggleButton-container {
                    .CustomToggleButton-main {
                      width: 33px;
                      height: 20px;

                      input:checked + .slider-button:before {
                        transform: translateX(14px);
                        top: 0px;
                      }

                      .slider-button {
                        &:before {
                          width: 17px;
                          height: 18px;
                          left: 0px;
                          bottom: 0;
                        }
                      }
                    }
                  }
                }

                &:last-child {
                  width: 13%;
                  padding-right: 0px;

                  .inputbox {
                    border: none;
                  }
                }
              }
            }
          }
        }

        .TextFieldNoRequired {
          .iconSection {
            &.left_icon {
              width: 40px;
              justify-content: center;

              .icon-left {
                color: $PHONEICONCOLOR;
              }
            }
          }

          .form-control {
            padding-left: 40px;
          }
        }

        .custombtnfield {
          float: left;
          width: 100%;
          display: flex;
          padding-top: 17px;
          padding-bottom: 16px;
          position: relative;
          padding-left: 15px;
          padding-right: 15px;

          &::before {
            content: "";
            width: calc(100% - 30px);
            height: 1px;
            background-color: $LINE_BORDER;
            position: absolute;
            top: 0px;
            left: 15px;
          }

          .custombtn {
            width: 164px;
            min-height: 36px;
            font-size: $FONT_16;
            line-height: 20px;
            padding: 5px 24px;
            margin-right: 16px;

            &.outline_btn {
              border: 0.5px solid $TEXT_SECONDARY_ONE !important;
              color: $TEXT_BLUE_DARK;
            }
          }
        }
      }
    }
  }
  .colorDropdownWrapper {
    position: relative;
    .arrow-dropdown {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 14px;
      color: $PLACEHOLDER;
    }
    .dropdownData {
      padding: 8px;
      .dropdown-item {
        background: $BG_WHITE;
        display: flex !important;
        grid-gap: 10px;
        padding: 10px 10px !important;
        border-radius: 8px;
        border-bottom: 0px !important;
        &:hover {
          background-color: $DROPDOWNHOVER !important;
          color: $TEXT_TITLE !important;
        }
      }
      .cricle_view {
        width: 16px;
        height: 16px;
        border-radius: 100px;
      }
      /* width */
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $TEXT_SECONDARY_ONE;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $BG_PURPLE;
      }
    }
    .dropdown-menu {
      box-shadow: 0px 0px transparent;
      border: 1px solid $INPUT_BORDER;
    }
    .cricle_select_view {
      width: 16px;
      height: 16px;
      display: block;
      border-radius: 100px;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      z-index: 1;
      left: 15px;
    }
    .customDropdown {
      button {
        padding: 12px 40px !important;
      }
    }
    .dropdownData {
      background: $BG_WHITE;
      border-radius: 8px;
    }
  }
  .townWrapper {
    position: relative;
    .arrow-dropdown {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 14px;
      color: $PLACEHOLDER;
    }
  }
  .onlineWrapper {
    display: flex;
    grid-gap: 10px;
    margin-bottom: 15px;
    .OnlineSwitchWrapper {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      padding: 10px 16px;
      border: 1px solid $LINE_BORDER_BOTTOM;
      border-radius: 8px;
      max-height: 47px;
      min-height: 47px;
      p {
        margin: 0px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .textField{
      margin-bottom: 0px !important;
    }
  }

  .select-search-select{
    padding: 8px !important;
    .select-search-options{
      background-color: transparent !important;
      .select-search-row{
          margin-bottom: 1px;
          border-bottom: 1px solid $UPLOADBGCOLOR !important;
        button {
          padding: 8px !important;
          border-radius: 8px;
          background: transparent;
          transition: 0.8s all;
          &.select-search-is-selected, &:hover{
            background-color: $UPLOADBGCOLOR !important;
            color: $TEXT_BLUE_DARK_LIGHT !important;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
    
  }

  .googleDropdown{
    border: 1px solid $LINE_BORDER_BOTTOM;
    border-radius: 8px 6px 0px 0px;
  }
  #react-select-2-listbox {
    margin: 0px;
  }
  .css-1n6sfyn-MenuList {
    padding: 8px;
    border: 1px solid $INPUT_BORDER;
    border-radius: 0px 0px 8px 8px;
    & > div {
      border-radius: 8px;
      color: $TEXT_TITLE !important;
      font-family: "SF Pro Text";
      font-size: 14px;
      &:hover {
        background-color: $DROPDOWNHOVER !important;
      }
    }
  }
  .map_view {
    line-height: 0px;
    iframe {
      max-height: 369px;
    }
  }
}

.CustomAddEvents .select-search-value {
  width: 100%;
}

.CustomAddEvents .select-search-container {
  width: 100%;
  background: transparent;
  font-family: "SF Pro Text";
  color: $TEXT_TITLE;
  font-size: 14px;
  padding-right: 40px;
  height: auto;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $BORDERCOLOR;
  outline: none;
  -webkit-appearance: none;
}

.CustomAddEvents .select-search-container input.select-search-input {
  border: 0px;
  background: transparent;
}

.add_business_card form > div > div {
  overflow-x: hidden !important;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .CustomAddEvents {
    .add_Business {
      padding: 0 20px;

      .add_business_container {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .CustomAddEvents {
    .add_Business {
      padding: 0 20px;

      .add_business_container {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .CustomAddEvents {
    .add_Business {
      padding: 0 20px;

      .add_business_container {
        width: 100%;

        .add_no_business {
          font-size: $FONT_16;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .CustomAddEvents {
    .add_Business {
      padding: 0 20px;

      .add_business_container {
        width: 100%;

        .add_no_business {
          font-size: $FONT_16;
        }
      }
    }
  }
}
