@import "../../../assets/scss/variables.scss";

.login {
  width: 100%;
  float: left;
  position: relative;
  background-color: $BG_Body;
  .formMain {
    position: relative;
    z-index: 9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .logoauth {
      position: absolute;
      left: 50%;
      margin-left: -64px;
      top: 50%;
      margin-top: -66px;
  }
  
    .formCenter {
      width: 614px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      padding: 40px 125px;
      display: block;
      height: auto;
      border-radius: 8px;
      background-color: $BG_WHITE;
      position: relative;
      .logo_bg {
        left: 0px;
        top: 0px;
        position: absolute;
        width: 100%;
        img{
          max-width: 100%;
        }
      }
      .labelTitle {
        margin: 320px 0 24px 0;
        text-align: left;
        font-family: $SFPROTEXT;
        font-size: $FONT_18;
        color: $TEXT_SECONDARY_TWO;
      }
      .textField{
        label{
          &::after{
            display: none;
          }
        }
      }
      .checkboxRow {
        width: 100%;
        float: left;
        padding-top: 10px;
        padding-bottom: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .forgotLink {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 15px;
        a {
          font-family: $SFPROTEXT;
          font-size: $FONT_14;
          color: $TEXT_SECONDARY_TWO;
          &:focus,
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

input[type="password"]
{
  -webkit-text-security: disc;
}

@media screen and ( max-height: 800px ) {
  .login{
    .formMain{
      justify-content: flex-start;
      .formCenter{
        width: 470px;
        padding: 40px 50px;
        .labelTitle{
          margin: 200px 0 24px 0;
        }
        .forgotLink {
          padding-top: 30px;
          padding-bottom: 0px;
        }
      }
    } 
  } 
}

@media only screen and (min-width:1025px) and (max-width:1366px){
  .login{
    .formMain{
      justify-content: flex-start;
      .formCenter{
        width: 470px;
        padding: 40px 50px;
        .logo_bg{
          img{
            // display: none;
          }
          // background-image: url(https://res.cloudinary.com/zudu/image/upload/v1633494539/Parish_Nursing/Parish-Nursing-Web/Project-images/mobile_logo_bg.png);
        }
        .labelTitle{
          margin: 200px 0 24px 0;
        }
        .forgotLink {
          padding-top: 30px;
          padding-bottom: 0px;
        }
      }
    } 
  } 
}
@media only screen and (min-width:768px) and (max-width:1024px){
  .login{
    .formMain{
      justify-content: flex-start;
      .formCenter{
        width: 470px;
        padding: 40px 50px;
        .labelTitle{
          margin: 200px 0 24px 0;
        }
        .forgotLink {
          padding-top: 30px;
          padding-bottom: 0px;
        }
      }
    } 
  } 
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:landscape) {
  .login{
    .formMain{
      justify-content: flex-start;
      .formCenter{
        width: 470px;
        padding: 40px 50px;
        .labelTitle{
          margin: 200px 0 24px 0;
        }
        .forgotLink {
          padding-top: 30px;
          padding-bottom: 0px;
        }
      }
    } 
  } 
}

@media only screen and (min-width:576px) and (max-width:767px){
  .login{
    .formMain{
      align-items: flex-start;
      padding: 40px 0;
      .formCenter{
        padding: 40px 60px;
      }
    } 
  } 
}
@media only screen and (max-width:575px){
  .login{
    .formMain{
      align-items: flex-start;
      padding: 40px 0;
      .formCenter{
        padding: 24px 24px;
      }
    } 
  } 
}


