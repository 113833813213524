@import "../../assets/scss/variables.scss";

.EventDetails {
	width: 100%;
	float: left;
	padding: 16px 20px;
	.first_row {
		width: 100%;
		float: left;
		background-color: $BG_BOX;
		padding: 16px 16px;
		border-radius: 8px;
		margin-bottom: 16px;
		&:nth-child(2) {
			margin-bottom: 0px;
		}
		.location {
			width: 100%;
			float: left;
			padding: 24px 0 10px;
			font-size: $FONT_16;
			font-weight: 400;
			line-height: 20px;
			color: $TEXT_SECONDARY_TWO;
		}
		.address_text {
			width: 100%;
			float: left;
			display: flex;
			font-size: $FONT_12;
			font-weight: 400;
			line-height: 16px;
			color: $TEXT_BLUE_DARK;
			padding-bottom: 10px;
			.icon-pin-outline {
				font-size: $FONT_14;
				color: $TEXT_SECONDARY_ONE;
				margin-right: 10px;
				opacity: 0.4;
			}
			.icon-calender-outline-fill {
				font-size: $FONT_14;
				color: $TEXT_SECONDARY_ONE;
				margin-right: 10px;
				opacity: 0.4;
			}
		}
		.padding_inner {
			.customDropdown {
				.selected {
					&.dropdown {
						.btn-primary {
							background-color: transparent !important;
							position: relative;
							display: flex;
							align-items: center;
							padding-left: 40px;
						}
					}
				}
				.dropdown {
					.btn-primary {
						background-color: #f9f9fa !important;
					}
					.dropdown-menu {
						.dropdownData {
							.dropdown-item {
								padding: 12px 14px;
							}
						}
					}
				}
			}
			.searchMainDropdown {
				.select-search-container {
					.select-search-value {
						.select-search-input {
							background-color: transparent;
							border: 1px solid $INPUT_BORDER;
						}
					}
				}
			}
		}
		.contain_row {
			display: flex;
			align-items: flex-start;
			.left_contain {
				width: 100%;
				display: grid;
				grid-template-columns: 1fr;
				grid-gap: 16px;
				.service_name {
					font-size: $FONT_16;
					line-height: 20px;
					font-weight: 400;
					color: $TEXT_SECONDARY_TWO;
					padding-bottom: 8px;
					padding-top: 24px;
				}
				.list_name {
					font-size: $FONT_16;
					line-height: 20px;
					font-weight: 600;
					color: $TEXT_SECONDARY_ONE;
					padding-bottom: 8px;
					word-break: break-word;
				}
				.list_discription {
					font-size: $FONT_12;
					font-weight: 400;
					line-height: 14px;
					color: $TEXT_BLUE_DARK;
				}
			}
			.icon-pencil {
				border: none;
				background-color: transparent;
				font-size: $FONT_20;
				color: $TEXT_SECONDARY_ONE;
				padding: 0px;
			}
		}
		.title_button_row {
			display: flex;
			align-items: flex-start;
			padding-bottom: 16px;
			.field_name {
				width: calc(100% - 16px);
				padding-right: 16px;
				font-size: $FONT_16;
				font-weight: 400;
				line-height: 20px;
				color: $TEXT_SECONDARY_TWO;
				&.eventdatetime {
					font-size: 16px;
					line-height: 20px;
					font-weight: 600;
					color: $BG_PURPLE;
					&::after {
						content: "*";
						color: $TEXT_RED;
					}
				}
			}
			.icon-pencil {
				border: none;
				background-color: transparent;
				font-size: $FONT_20;
				color: $TEXT_SECONDARY_ONE;
				padding: 0px;
			}
		}
		.map_view {
			width: 100%;
			float: left;
			.map {
				border-radius: 8px;
				width: 100%;
				float: left;
				height: 370px;
			}
		}
		.sub_title {
			width: 100%;
			float: left;
			color: $TEXT_SECONDARY_ONE;
			font-size: $FONT_16;
			font-weight: 600;
			line-height: 20px;
			padding-bottom: 16px;
		}

		.day_time_row {
			display: flex;
			align-items: center;
			padding-bottom: 20px;
			&:last-child {
				padding-bottom: 0px;
			}
			.day_text {
				width: 50%;
				display: flex;
				align-items: center;
				font-size: $FONT_14;
				font-weight: 400;
				line-height: 18px;
				color: $TEXT_SECONDARY_TWO;
				span {
					color: #9cadcc !important;
					font-size: $FONT_22;
					margin-right: 8px;
				}
			}
			.time_text {
				width: 50%;
				display: flex;
				align-items: center;
				font-size: $FONT_14;
				font-weight: 400;
				line-height: 18px;
				color: $TEXT_SECONDARY_TWO;
				span {
					color: $PHONEICONCOLOR;
					font-size: $FONT_22;
					margin-right: 8px;
				}
			}
		}
		.information_row {
			display: flex;
			align-items: center;
			padding-bottom: 20px;
			&:last-child {
				padding-bottom: 0px;
			}
			.linkTxt {
				width: 50%;
				display: flex;
				align-items: center;
				font-size: $FONT_14;
				font-weight: 400;
				line-height: 18px;
				color: $TEXT_SECONDARY_TWO;
				span {
					color: $PHONEICONCOLOR;
					font-size: $FONT_22;
					margin-right: 8px;
				}
			}
		}
		.padding_inner {
			.input_row {
				width: 100%;
				float: left;
				position: relative;
				.customDropdown {
					.btn-primary {
						background-color: transparent;
					}
					.dropdown {
						.dropdown-menu {
							top: 48px !important;
							transform: none !important;
						}
					}
				}
				.cricle_view {
					width: 16px;
					height: 16px;
					border-radius: 50%;
					margin-right: 10px;
					float: left;
				}
			}
			.discription_txt {
				width: 100%;
				float: left;
				position: relative;
				.form-control {
					padding-right: 16px;
					height: 96px;
					resize: none;
					vertical-align: top;
				}
				.textarea_count {
					position: absolute;
					right: 16px;
					top: 70px;
					font-size: $FONT_14;
					line-height: 24px;
					font-weight: 400;
					font-family: $SFPROTEXT;
					text-align: right;
					color: $PLACEHOLDER;
				}
			}
		}
		.townWrapper {
			margin-top: 24px;
		}
		.select-search-select {
			padding: 8px !important;
			.select-search-options {
				background-color: transparent !important;
				.select-search-row {
					margin-bottom: 1px;
					border-bottom: 1px solid #f9f9f9 !important;
					button {
						padding: 8px !important;
						border-radius: 8px;
						background: transparent;
						transition: 0.8s all;
						&.select-search-is-selected,
						&:hover {
							background-color: $DROPDOWNHOVER !important;
							color: $BUTTONCOLOR !important;
							font-size: 14px;
							font-weight: 400;
						}
					}
				}
			}
		}
	}
	.edit_row {
		width: 100%;
		float: left;
		.title_button_row {
			align-items: center;
			padding-bottom: 22px;
			.field_name {
				width: 70%;
			}
		}
		.custombtnfield {
			width: 30%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.custombtn {
				width: 56px;
				margin-right: 4px;
				font-size: $FONT_12;
				font-weight: 400;
				line-height: 14px;
				min-height: 22px;
				padding: 0px;
				border-radius: 30px;
				&.outline_btn {
					margin-right: 0px;
					color: $TEXT_SECONDARY_ONE;
					border-color: $LINE_BORDER !important;
					background-color: transparent !important;
				}
			}
		}
		.location_part {
			width: 100%;
			float: left;
			border-radius: 8px;
			border: 1px solid $INPUT_BORDER;
			&.disable {
				.googleDropdown {
					pointer-events: none;
					opacity: 0.4;
				}
			}
			.map_view {
				.map {
					border-radius: 0px 0px 8px 8px;
				}
			}
			.googleDropdown .css-1pahdxg-control,
			.googleDropdown .css-yk16xz-control {
				border-radius: 0px 0px 0px 0px;
				border-top: none;
				border-right: none;
				border-left: none;
			}
		}
		.check_row {
			width: 100%;
			float: left;
			display: flex;
			flex-wrap: wrap;
			.labelTitle {
				width: 100%;
				float: left;
				font-size: $FONT_16;
				font-weight: 400;
				line-height: 20px;
				color: $TEXT_SECONDARY_TWO;
				padding-bottom: 10px;
			}
			.checkboxMain {
				padding: 0px 0 16px;
				input:checked + label::before {
					border-color: $TEXT_BLUE_DARK;
					border-width: 1px;
					border-radius: 2px;
					background: transparent;
				}
				input:checked + label:after {
					border-color: $TEXT_BLUE_DARK;
					top: 4px;
					left: 6px;
				}
				label {
					color: $TEXT_BLUE_DARK;
					&:before {
						border-color: $TEXT_BLUE_DARK;
						border-width: 1px;
						border-radius: 2px;
					}
				}
			}
		}
		.address_part {
			width: 100%;
			float: left;
			&.disable {
				.textField {
					opacity: 0.4;
					.form-control {
						&:disabled {
							background: $BG_WHITE;
							pointer-events: none;
						}
					}
				}
			}
		}
		.date-filter-main {
			.datetime_title {
				color: $TEXT_SECONDARY_TWO;
			}
		}
	}

	.TagWrapper {
		display: flex;
		align-items: center;
		grid-gap: 10px;
		margin-bottom: 8px;
		.list_name {
			padding: 0px !important;
		}
		.tagWrapper {
			padding: 6px 12px;
			border-radius: 100px;
			display: flex;
			align-items: center;
			font-size: 12px;
			font-weight: 400;
			line-height: normal;
			color: $TEXT_TITLE;
			&.sucess-bg {
				background: $SUCESSCOLOR;
			}
		}
	}

	.select-search-value {
		input {
			border: 1px solid $INPUT_BORDER !important;
			border-radius: 8px !important;
			font-size: 14px;
			color: $TEXT_SECONDARY_TWO;
		}
	}
}

.business_details {
	.colorDropdownWrapper {
		position: relative;
		.arrow-dropdown {
			position: absolute;
			top: 50%;
			margin-top: -8px;
			right: 14px;
			color: $DATETEXT;
		}
		.dropdownData {
			padding: 8px;
			.dropdown-item {
				background: $BG_WHITE;
				display: flex !important;
				grid-gap: 10px;
				padding: 10px 10px !important;
				border-radius: 8px;
				border-bottom: 0px !important;
				&:hover {
					background-color: $DROPDOWNHOVER !important;
					color: $TEXT_TITLE !important;
				}
			}
			.cricle_view {
				width: 16px;
				height: 16px;
				border-radius: 100px;
			}
			/* width */
			&::-webkit-scrollbar {
				width: 6px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: $SCROLLTRACK;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: $BG_PURPLE;
				border-radius: 8px;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: $BG_PURPLE;
			}
		}
		.dropdown-menu {
			box-shadow: 0px 0px transparent;
			border: 1px solid $DROPDOWNBORDER;
		}
		.cricle_select_view {
			width: 16px;
			height: 16px;
			display: block;
			border-radius: 100px;
			position: absolute;
			top: 50%;
			margin-top: -8px;
			z-index: 1;
			left: 15px;
		}
		.customDropdown {
			button {
				padding: 12px 40px !important;
			}
		}
		.dropdownData {
			background: #ffffff;
			border-radius: 8px;
		}
	}
	.AssociatedservicesWrapper {
		.eventData_name {
			font-size: $FONT_16;
			font-weight: 400;
			color: $TEXT_TITLE;
			margin-bottom: 8px;
		}
	}
	.location-part {
		.list_name {
			font-size: 16px;
			line-height: 20px;
			font-weight: 600;
			color: $BG_PURPLE;
			padding-bottom: 8px;
		}
		.address_text {
			display: flex;
			align-items: center;
			span.icon-pin-outline {
				font-size: 22px !important;
			}
			.map_view {
				iframe.map {
					min-height: 305px;
					max-height: 305px;
				}
			}
		}
		.map_view {
			iframe {
				max-height: 305px;
				min-height: 305px;
			}
		}
	}
	.townWrapper {
		margin-top: 0px !important;
		ul {
			display: flex;
			grid-gap: 8px;
			flex-wrap: wrap;
			li {
				font-size: 12px;
				color: $TIMINGHEADING;
				font-weight: 400;
			}
		}
		position: relative;
		.arrow-dropdown {
			position: absolute;
			top: 50%;
			margin-top: -8px;
			right: 14px;
			color: $DATETEXT;
		}
	}
	.onlineWrapper {
		display: flex;
		grid-gap: 10px;
		margin-bottom: 15px;
		.OnlineSwitchWrapper {
			display: flex;
			align-items: center;
			grid-gap: 10px;
			padding: 10px 16px;
			border: 1px solid $LINE_BORDER;
			border-radius: 8px;
			max-height: 47px;
			min-height: 47px;
			p {
				margin: 0px;
				font-size: 16px;
				font-weight: 400;
			}
		}
		.textField {
			margin-bottom: 0px;
		}
	}
	.location_part {
		.map_view {
			iframe {
				min-height: 305px;
				max-height: 305px;
			}
		}
	}
}
