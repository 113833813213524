@import "../../assets/scss/variables.scss";

.CustomLeftPart {
	width: 100%;
	float: left;
	.sidebarauthbg {
		position: relative;
		.bgSidebar {
			img {
				width: 100%;
			}
		}
		.Sidebarlogo {
			position: absolute;
			top: 0;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px 0px;
			img {
				max-width: 30%;
			}
		}
	}
	.logo {
		width: 100%;
		float: left;
		height: 147px;
		position: relative;
		.icon-close {
			position: absolute;
			right: 0px;
			top: 0;
			color: $TEXT_WHITE;
			font-size: $FONT_16;
			background-color: transparent;
			border: none;
			margin: 10px 10px 0 0;
			display: none;
			padding: 0px;
		}
	}
	.row_part {
		width: 100%;
		float: left;
		padding: 46px 20px 20px;
		.categories_title {
			font-size: $FONT_14;
			line-height: 18px;
			color: $TEXT_BLUE_DARK;
			font-family: $SFPROTEXT;
			font-weight: 400;
			padding-bottom: 16px;
			.count_number {
				margin-left: 8px;
				font-size: $FONT_12;
				line-height: 14px;
				color: $TEXT_SECONDARY_ONE;
				background-color: $BG_OPACITY;
				padding: 2px 8px;
				border-radius: 30px;
			}
		}
	}
	.data_list {
		width: 100%;
		float: left;
	}
	.nodatafound {
		display: flex;
		justify-content: center;
		height: 100%;
		align-items: center;
	}
	.add_category_btn {
		width: 100%;
		display: flex;
		padding: 20px 23px;
	}
}
.data_list > div > div {
	overflow-x: hidden !important;
	margin-bottom: -1px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.CustomLeftPart {
		.logo {
			.icon-close {
				display: block;
			}
		}
	}
}
