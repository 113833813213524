
@import "../../assets/scss/variables.scss";

.privacy-head span {
    display: block;
    font-size: 14px;
    font-weight: normal
    ;
}
.privacyModal .modal-dialog {
    max-width: 847px;
    .modal-content{
        border-radius: 25px;
        border: 0px;
        .modal-header{
            border: 0px;
          .modal-title{
           &.h4 {
             margin: 30px 0px 0px;
             font-size: 20px;
             color: $TEXT_TITLE;
             font-weight: 600;
         }
          }
        }
      }
}