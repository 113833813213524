@import "../../../assets/scss/variables.scss";

.dashboardpage {
    width: 100%;
    float: left;
    background-color: $BG_Body;
    .category_btn{
        display: none;
    }
    .left_part{
        width: 317px;
        float: left;
        background-color: $BG_WHITE;
        position: fixed;
        top: 0;
    }
    .right_part{
        width: calc(100% - 317px);
        float: left;
        margin-left: 317px;
    }
}


@media only screen and (min-width:768px) and (max-width:991px){
    .dashboardpage{
        .category_btn{
            border: none;
            background-color: transparent;
            color: $TEXT_WHITE;
            font-size: $FONT_16;
            font-weight: 600;
            line-height: 20px;
            font-family: $SFPROTEXT;
            padding: 0px;
            display: flex;
            align-items: center;
            position: fixed;
            left: 20px;
            top: 32px;
            z-index: 9;
            .icon-menu{
              margin-right: 18px;
              font-size: $FONT_18;
            }
          }
        .left_part{
            left: -317px;
            z-index: 11;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            &.leftPartshow{
                left: 0px; 
                
            }
        }
        .right_part{
            width: 100%;
            margin-left: 0px;
        }
    } 
}
