@import "../../assets/scss/variables.scss";

.AppConfigurationsPage {
  padding: 0 15px;
  width: 100%;
  display: inline-block;
  position: relative;
  .contact_details {
    width: 100%;
    padding: 40px 40px 16px;
    .contact_box {
      width: 100%;
      background-color: $CONTACTBOXCOLOR;
      padding: 20px 16px;
      .title_row {
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px;
        .title_txt {
          font-size: $FONT_14;
          line-height: 16px;
          font-weight: 400;
          font-family: $SFPROTEXT;
          color: $TEXT_SECONDARY_ONE;
        }
        .icon-pencil {
          padding: 0px;
          margin: 0px;
          font-size: $FONT_14;
          color: $TEXT_SECONDARY_ONE;
          border: none;
          background-color: transparent;
          outline: none;
          font-weight: 600;
        }
      }
      .field_row {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: 16px;
        .input_title {
          width: 100%;
          font-size: $FONT_14;
          line-height: 16px;
          color: $TEXT_BLUE_DARK;
          font-weight: 400;
          font-family: $SFPROTEXT;
          padding-bottom: 4px;
        }
        .input_txt {
          width: 100%;
          font-size: $FONT_14;
          line-height: 16px;
          color: $TEXT_SECONDARY_TWO;
          font-weight: 400;
          font-family: $SFPROTEXT;
          word-break: break-word;
          &.outlined {
            border: 1px solid $INPUT_BORDER;
            border-radius: 5px;
            padding: 10px;
            &:last-child {
              margin-top: 10px;
            }
          }
        }
      }
    }
    &.edit_contact_details {
      .title_txt {
        width: 100%;
        padding-bottom: 15px;
        font-size: $FONT_14;
        line-height: 16px;
        font-weight: 400;
        font-family: $SFPROTEXT;
        color: $TEXT_SECONDARY_ONE;
      }
      .input_title {
        width: 100%;
        font-size: $FONT_14;
        line-height: 16px;
        color: $TEXT_SECONDARY_TWO;
        font-weight: 400;
        font-family: $SFPROTEXT;
        padding-bottom: 8px;
      }
      .TextFieldNoRequired {
        border-radius: 8px;
        background-color: transparent !important;
        .errorMsg {
          left: 0px;
          background: transparent !important;
          width: 100%;
        }
      }
      .inputBoxWrapper {
        padding: 16px;
        margin-bottom: 15px;
        background: #f8f9fb;
        .TextFieldNoRequired {
          float: unset;
          margin: 0px;
          .form-control {
            border: 1px solid $BORDERCOLOR;
            background: #f8f9fb !important;
          }
        }
      }
      .phone_view {
        width: 100%;
        margin-bottom: 16px;
        background: #f8f9fb;
        border-radius: 8px;
        padding: 16px 16px;
        .countryPhoneGroup {
          .react-tel-input {
            padding-left: 0px;
            position: absolute;
            min-height: 46px;
            left: 0;
            .form-control {
              text-align: center;
            }
          }
          &::before {
            width: 100%;
            margin: 0px;
            border: 1px solid $BORDERCOLOR;
            background: #f8f9fb !important;
          }
          margin: 0px;
          .icon-phone {
            z-index: 1;
          }
          &::before {
            width: calc(100% + 2px);
            left: -1px;
          }
          .TextFieldNoRequired {
            padding-left: 0px;
            background-color: transparent;
            min-height: 46px;
            .form-control {
              background-color: transparent !important;
              padding-left: 50px !important;
            }
          }
        }

        .TextFieldNoRequired .form-control {
          padding-right: 16px !important;
        }
      }
      .location_section {
        width: 100%;
        background: #f8f9fb;
        border-radius: 8px;
        padding: 16px 16px;
        .TextFieldNoRequired {
          padding: 0px;
          border-radius: 0px;
          background-color: transparent;
        }
        .location_part {
          width: 100%;
          .googleDropdown {
            .css-yk16xz-control {
              border-radius: 8px 8px 0px 0px;
              border-bottom: none;
            }
            .css-1wa3eu0-placeholder {
              &::after {
                display: none;
              }
            }
          }
          .map_view {
            width: 100%;
            padding: 0px 0 0;
            border: 1px solid $BORDERCOLOR;
            border-radius: 0px 0px 8px 8px;
            overflow: hidden;
            line-height: 0px;
            .map {
              width: 100%;
              height: 177px !important;
            }
          }
        }

        .css-1n6sfyn-MenuList {
          padding: 8px;
          border: 1px solid $BORDERCOLOR;
          border-radius: 0px 0px 8px 8px;
          & > div {
            border-radius: 8px;
            color: $TEXT_TITLE !important;
            font-family: "SF Pro Text";
            font-size: 14px;
            &:hover {
              background-color: $DROPDOWNHOVER !important;
            }
          }
        }
      }
      .custombtnfield {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 12px;
        .custombtn {
          min-height: 36px;
          padding: 0px;
          width: 48%;
        }
      }
    }
  }

  .other_details {
    width: 100%;
    padding: 0px 40px 0;
    .other_title {
      font-size: $FONT_16;
      line-height: 20px;
      font-weight: 600;
      font-family: $SFPROTEXT;
      color: $TEXT_SECONDARY_ONE;
      border-top: 1px solid $PLACEHOLDER;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .contact_box {
      width: 100%;
      background-color: $CONTACTBOXCOLOR;
      padding: 12px 16px;
      .title_row {
        display: flex;
        justify-content: space-between;
        .title_txt {
          font-size: $FONT_14;
          line-height: 16px;
          font-weight: 400;
          font-family: $SFPROTEXT;
          color: $TEXT_SECONDARY_TWO;
        }
        .icon-pencil {
          padding: 0px;
          margin: 0px;
          font-size: $FONT_14;
          color: $TEXT_SECONDARY_ONE;
          border: none;
          background-color: transparent;
          outline: none;
          font-weight: 600;
        }
      }
      .privacyWrapper{
        .title_txt{
          font-size: 14px;
          color: $PLACEHOLDER;
        }
      display: flex;
      grid-gap: 17px;
      }
    }
  }
}
