@import "../../assets/scss/variables.scss";

.checkboxMain {
  display: block;
}

.checkboxMain input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkboxMain label {
  position: relative;
  cursor: pointer;
  font-family: $SFPROTEXT;
  font-weight: 400;
  font-size: $FONT_16;
  line-height: 20px;
  color: $TEXT_BLUE_DARK_LIGHT;
  margin: 0;
}

.checkboxMain label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid $TEXT_SECONDARY_ONE;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 3px;
  top: -1px;
}

.checkboxMain input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid $TEXT_WHITE;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkboxMain input:checked + label::before {
  background-color: $BG_SECONDARY_ONE;
  border: 2px solid $BG_SECONDARY_ONE;
}
