@import "../../assets/scss/variables.scss";
.CustomNavigationsMain {
	padding: 0;
	width: 100%;
	float: left;
	display: inline-block;
	position: relative;
	&.disable{
		background-color: $BG_OPACITY;
		opacity: 0.9;
		.full_btn{
			.active_bg{
				background-color: $BG_OPACITY !important;
			}
			.title_left{
				.title_txt{
					color: $PLACEHOLDER;
					font-weight: 500;
				}
			}
			&:hover{
				.action_right{
					justify-content: flex-end;
				}
			}
			.action_right {
				display: block !important;
			}
		}
	}
	.full_btn {
		width: 100%;
		float: left;
		border-bottom: 1px solid $LINE_BORDER_BOTTOM;
		border-top: none;
		border-left: none;
		border-right: none;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: space-between;
		outline: 0;
		padding: 20px 0 20px 20px;
		cursor: pointer;
		position: relative;
		flex-wrap: wrap;
		min-height: 68px;
		transition: 0.8s all;
		box-shadow: 0px 2px 16px transparent, 0px 8px 16px transparent;
		&.selected_bg{
			.active_bg{
				display: flex;
			}
			.action_right{
				display: block !important;
			}
		}
		.active_bg{
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			opacity: 0.2;
			display: none;
		}
		.title_left{
			width:calc(100% - 110px);
			float: left;
			font-size: $FONT_14;
			color: $TEXT_BLUE_DARK;
			font-family: $SFPROTEXT;
			font-weight: 500;
			line-height: 18px;
			display: flex;
			align-items: flex-start;
			z-index: 1;
			transition: 0.4s all;
			.title_txt {
				text-align: left;
				top: -2px;
				padding-left: 24px;
				.text{
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					position: relative;
					white-space: nowrap;
					.txt_title{
						padding-right: 30px;
					}
				}
			}
			.cricle_bg{
				content: "";
				position: absolute;
				left: 20px;
				width: 16px;
				height: 16px;
				float: left;
				margin-right: 8px;
				background-color:#B36EFB;
				border-radius: 50%;
			}
			.count_number{
				margin-left: 8px;
				font-size: $FONT_12;
				line-height: 14px;
				color: $TEXT_SECONDARY_ONE;
				background-color: $BG_OPACITY;
				padding: 2px 8px;
				border-radius: 30px;
				position: relative;
				top: 0px;
				right: 0px;
			}
		}
		.action_right{
			display: none !important;
			align-items: flex-start;
			z-index: 1;
			position: relative;
			right: 3px;
			top: 0;
			height: 100%;
			width: 102px;
			display: flex;
			align-items: center;
			button{
				border: none;
				background-color: transparent;
				padding: 0px;
				margin:0 6px;
				outline: none;
				&.icon-pencil{
					color: $TEXT_SECONDARY_ONE;
					font-size: $FONT_18;
				}
				&.icon-delete{
					color: $TEXT_RED;
					font-size: $FONT_18;
				}
			}
			.CustomToggleButton-container{
				margin: 0 8px 0 6px;
				float: right;
			}
		}
		&:hover{
			-webkit-box-shadow: 0px 2px 16px rgba(99, 99, 99, 0.06), 0px 8px 16px rgba(99, 99, 99, 0.06);
			-moz-box-shadow: 0px 2px 16px rgba(99, 99, 99, 0.06), 0px 8px 16px rgba(99, 99, 99, 0.06);
			-ms-box-shadow: 0px 2px 16px rgba(99, 99, 99, 0.06), 0px 8px 16px rgba(99, 99, 99, 0.06);
			-o-box-shadow: 0px 2px 16px rgba(99, 99, 99, 0.06), 0px 8px 16px rgba(99, 99, 99, 0.06);
			box-shadow: 0px 2px 16px rgba(99, 99, 99, 0.06), 0px 8px 16px rgba(99, 99, 99, 0.06);
			.active_bg{
				display: flex;
			}
			.title_left{
				font-weight: 500;
				color: $TEXT_SECONDARY_ONE;
				.count_number{
					background-color: $BG_WHITE;
				}
			}
		}
	}
}