@import "../../assets/scss/variables.scss";

.calenderContainer {
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    float: left;
    border: 1px solid;
    border-color: $INPUT_BORDER;
    font-family: $SFPROTEXT;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      background-color: $BG_WHITE;
      font-family: $SFPROTEXT;
      font-size: $FONT_16;
      color: $TEXT_SECONDARY_ONE;
      border-bottom: 0;

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: $TEXT_RED;
        font-weight: normal;
        font-family: $SFPROTEXT;
        font-size: $FONT_14;
        color: $TEXT_SECONDARY_ONE;
        font-weight: 600;
      }
    }

    .react-datepicker__navigation--next {
      display: flex;
      align-items: center;
      justify-content: center;
      .react-datepicker__navigation-icon {
        font-size: inherit;
        &::before {
          border-color: $TEXT_RED;
        }
      }
      &:focus {
        outline: none;
      }
    }

    .react-datepicker__navigation--previous {
      display: flex;
      align-items: center;
      justify-content: center;
      .react-datepicker__navigation-icon {
        font-size: inherit;
        &::before {
          border-color: $TEXT_RED;
        }
      }
      &:focus {
        outline: none;
      }
    }

    .react-datepicker__day-name {
      font-size: $FONT_14;
      color: $TEXT_RED;
      padding: 2px 0 0 0;
      font-family: $SFPROTEXT;
      margin: 3px;
      width: 32px;
      height: 32px;
      text-align: center;
      font-weight: normal;
      border-radius: 0;
    }

    .react-datepicker__day {
      font-size: $FONT_14;
      color: $TEXT_RED;
      padding: 2px 0 0 0;
      font-family: $SFPROTEXT;
      margin: 3px;
      width: 32px;
      height: 32px;
      text-align: center;
      font-weight: normal;
      border-radius: 0;

      &:hover {
        background-color: $INPUT_BORDER;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: $INPUT_BORDER !important;
      color: $TEXT_RED;
      &:focus {
        outline: none;
      }
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      cursor: default !important;
      color: #ccc;
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}
