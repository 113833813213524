@import "../../assets/scss/variables.scss";

.banner {
  .WebsidebarFilter {
    position: relative;
    .bgSidebar {
      img {
        width: 100%;
      }
    }
    .Sidebarlogo {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -55px;
      margin-left: -64px;
      img {
        max-width: 75%;
      }
    }
  }

}
h1,
h2 {
  font-size: $FONT_16 !important;
  font-weight: 600;
  line-height: 19px;
  color: $TEXT_PURPLE;
  font-family: $SFPRODISPLAY;
}

.filterWrap {
  .btn-primary {
    background-color: $YELLOW_LIGHT;
    color: $TEXT_PURPLE;
    border-radius: 0;
    line-height: 2.5;
    height: 48px;
    width: 48px;
    border: none;
    position: fixed;
    right: -48px;
    top: 20px;
    left: 0;
    top: 80px;
    z-index: 2;
    transition: 0.3s ease;
    border-radius: 0px 8px 8px 0px;
    &:hover {
      background-color: $YELLOW_LIGHT;
      color: $TEXT_PURPLE;
    }
  }

  &.active {
    .btn-primary {
      left: 317px;
    }
  }
  .filter-title{
    font-size: $FONT_16 !important;
    font-weight: 600;
    font-family: $SFPRODISPLAY;
    color: $TIMINGHEADING;
    margin-bottom: 20px;
  }
  .innerWrap {
    .filterTypeWrap {
      padding: 0 20px;

      h1 {
        margin-top: 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid $BG_LIGHTGREY;
        font-family: $SFPRODISPLAY;
        font-weight: 400;
        color: $TIMINGHEADING;
        display: flex;
        align-items: center;
        grid-gap: 10px;

        span {
          background: $BG_YELLOW2;
          padding: 2px 4px;
          font-weight: 400;
          font-size: $FONT_12;
          line-height: 14px;
          text-align: center;
          border-radius: 4px;
        }
      }

      .categories {
        border-bottom: 1px solid $BG_LIGHTGREY;

        ul {
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            a {
              font-weight: lighter;
            }

            span.color {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: inline-block;
            }
          }
        }
      }

      .onGoing {
        display: flex;
        justify-content: space-between;
        background: #f3f3f6;
        border-radius: 25px;
        margin-top: 20px;
        padding: 12px 16px;
        color: $TEXT_TITLE;
        font-weight: lighter;
        font-size: $FONT_14;

        .title {
          padding: 0;
          margin: 3px 0 0 0;
          font-family: "SF Pro Display";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $TEXT_TITLE;
        }
      }
    }
    .location-wrapper{
      margin: 20px 0px;
      border-bottom: 1px solid #ECECEC;
      
      .lacationFilterWrapper{
        position: relative;
        span.arrow-dropdown.icon-sorting {
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -8px;
          margin-right: 14px;
          color: $BG_SECONDARY_ONE;
      }
      .multipleSelectBox{
        .react-select__control{
          margin-bottom: 20px;
        }
        .react-select__single-value{
          font-size: 14px;
          font-weight: 600;
          color: $TIMINGHEADING;
          font-family: $SFPRODISPLAY;
        }
        .react-select__menu{
          margin: 0px;
        }
        .react-select__option{
          flex-direction: row;
          input[type="checkbox"] ~ label{
            display: none;
          }
        }
      }
      }
    }

    .filterCategories {
      margin: 0px 0px 20px 0px;
      ul {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;
        margin: 0px 0px 20px 0px !important;
      .checkboxLabel{
        font-size: 15px;
        font-weight: 400;
      }
      .checked{
        border-width: 1px !important;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        i{
          top: -1px;
          width: 6px;
          height: 12px;
        }
      }
    }
    }

    .filterDomain{
      border-bottom: 1px solid $TEXT_GREY_LIGHT;
      .checkboxMain{
        margin-bottom: 24px;
        input:checked + label::before, label:before{
          width: 16px;
          height: 16px;
          background: transparent;
          border: 1px solid $ICONCOLOR;
        }
        input:checked + label:after{
          border: solid $ICONCOLOR;
          border-width: 0 2px 2px 0 !important;
          top: 5px;
          left: 6px;
          width: 4px;
          height: 8px;
        }
      }
    }

    .onGoing-Wrapper{
     margin: 20px 0px 0px !important;
     min-height: 48px !important;
     display: flex;
     align-items: center;
      .CustomToggleButton-main {
        width: 40px;
        .slider-button.round{
          &:before {
            width: 24px;
            height: 24px;
            bottom: 0px;
            left: 0px;
            transform: translate(0px, 4px);
        }
        }
        input:checked + .slider-button:before {
          transform: translate(20px, 4px);
          bottom: 0px;
          left: 0px;
          top: unset !important;
      }
    }
    }
  }
}

@media (max-width: 940px) {
  .filterWrap {
    &.active {
      .btn-primary {
        left: 277px;
      }
    }
  }
}
@media only screen and (min-device-width: 375px) {
  .filterWrap {
    .onGoing {
      display: flex;
      justify-content: space-between;
      background: #f3f3f6;
      border-radius: 25px;
      margin-top: 10px !important;
      padding: 10px 15px !important;
      color: $TEXT_TITLE;
      font-weight: lighter;
      font-size: $FONT_14;

      .title {
        padding: 0;
        margin: 3px 0 0 0;
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $TEXT_TITLE;
      }
    }
  }
}
