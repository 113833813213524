@import "../../assets/scss/variables.scss";

.confirmModal {
  .modal-dialog {
    width: 256px !important;
    max-width: 100%;
    box-shadow: 0px 0px !important;
    .modal-content {
      border: 0;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      -ms-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      -o-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      padding: 26px 14px 16px 14px;
      background-color: $BG_GREY_LIGHT !important;
      .icon-warning {
        font-size: 72px;
        width: 100%;
        text-align: center;
        color: $BG_YELLOW;
      }
      .modal-header {
        padding: 15px 0 8px 0;
        border-radius: 0;
        border: 0;
        text-align: center;
        position: relative;
        display: block;
        p {
          margin: 0;
          font-family: $SFPROTEXT;
          font-size: $FONT_16;
          line-height: 24px;
          color: $TEXT_SECONDARY_TWO;
          font-weight: 600;
        }
      }
      .modal-body {
        padding: 0px;
        p {
          margin: 0;
          text-align: center;
          font-family: $SFPROTEXT;
          font-size: $FONT_14;
          line-height: 18px;
          color: $TEXT_SECONDARY_TWO;
        }
      }
      .modal-footer {
        padding: 30px 0 0px 0;
        border: 0;
        border-radius: 0;
        margin: 0px;
        justify-content: space-between;
        .custombtn {
          width: 108px;
          margin: 0px;
          padding: 0 10px;
          font-size: $FONT_14;
          font-weight: 400;
          min-height: 40px;
          &:nth-child(1) {
            background-color: transparent;
            color: $TEXT_SECONDARY_TWO;
          }
        }
      }
    }
  }
  &.confirm-delete {
    .modal-dialog {
      .modal-content {
        .icon-warning {
          color: $TEXT_RED;
        }
      }
    }
  }
}
