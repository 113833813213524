@import "../../assets/scss/variables.scss";

.BusinessDetails {
	width: 100%;
	float: left;
	padding: 16px 20px;
	.first_row {
		width: 100%;
		float: left;
		background-color: $BG_BOX;
		padding: 16px 16px;
		border-radius: 8px;
		margin-bottom: 16px;
		position: relative;
		.EditServices {
			button.icon-pencil {
				border: 0px;
				background: transparent;
				font-size: 20px;
				color: $TEXT_PURPLE;
			}
		}
		.EditServices {
			position: absolute;
			top: 15px;
			right: 15px;
		}

		.EditServices button.icon-pencil {
			border: 0px;
			background: transparent;
			font-size: 20px;
			color: $BG_PURPLE;
		}

		.TagWrapper {
			display: flex;
			align-items: center;
			grid-gap: 10px;
			margin-bottom: 8px;
			.list_name {
				padding: 0px !important;
			}
			.tagWrapper {
				padding: 6px 12px;
				border-radius: 100px;
				display: flex;
				align-items: center;
				font-size: 12px;
				font-weight: 400;
				line-height: normal;
				color: $TEXT_TITLE;
				&.sucess-bg {
					background: $SUCESSCOLOR;
				}
			}
		}
		.liveViewWrapper {
			display: flex;
			align-items: center;
			grid-gap: 10px;
			.liveservericon {
				display: flex;
				align-items: center;
				span {
					font-size: 35px;
					color: $BG_SECONDARY_ONE;
				}
			}

			.liveservertext {
				font-size: 14px;
				line-height: 17px;
				color: $BG_SECONDARY_TWO;
				border-bottom: 1px solid $BG_SECONDARY_TWO;
				&:hover {
					color: $BG_SECONDARY_TWO;
				}
			}
		}

		.townWrapper {
			ul {
				display: flex;
				grid-gap: 8px;
				flex-wrap: wrap;
				li {
					font-size: 12px;
					color: $TEXT_GREY;
					font-weight: 400;
				}
			}
		}

		.lacation-wrapper {
			.lacation-name-style {
				margin-bottom: 0px !important;
				border-bottom-left-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
			}
			.map_view {
				border-top: 0px !important;
				border: 1px solid $BORDERCOLOR;
				border-bottom-left-radius: 8px !important;
				border-bottom-right-radius: 8px !important;
				line-height: 0;
			}
		}

		.Event-wrapper {
			background-color: $BG_WHITE;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			padding: 10px;
			border-radius: 10px;
			grid-gap: 15px;
			margin-bottom: 15px;
			.sub_title {
				padding: 0px;
			}
			.address_text {
				font-size: 12px !important;
				font-weight: 400 !important;
				color: $BG_BLUE_DARK !important;
				display: flex !important;
				align-items: center !important;
				font-family: $SFPRODISPLAY;
				padding: 0px;
				grid-gap: 10px;
				span {
					font-size: 22px !important;
					color: #9cadcc !important;
				}
				&:last-child {
					padding-bottom: 0px;
				}
			}
		}

		.address_text {
			&.address_input {
				padding: 12px 16px !important;
				border: 1px solid $BORDERCOLOR;
				border-radius: 8px;
				font-size: 14px !important;
				color: $TEXT_SECONDARY_ONE !important;
				font-weight: 400 !important;
				line-height: 24px !important;
				margin-bottom: 16px;
			}
		}

		.sort_name {
			width: 64px;
			height: 64px;
			border-radius: 8px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: $FONT_24;
			line-height: 30px;
			font-weight: 600;
			color: $TEXT_SECONDARY_ONE;
			.full_bg {
				position: absolute;
				border-radius: 8px;
				width: 100%;
				height: 100%;
				top: 0px;
				left: 0px;
				z-index: -1;
				opacity: 1;
			}
		}
		.padding_inner {
			.customDropdown {
				.selected {
					&.dropdown {
						.btn-primary {
							background-color: transparent !important;
							position: relative;
							display: flex;
							align-items: center;
							padding-left: 40px;
						}
					}
				}
				.dropdown {
					.btn-primary {
						background-color: #f9f9fa !important;
					}
					.dropdown-menu {
						.dropdownData {
							.dropdown-item {
								padding: 12px 14px;
							}
						}
					}
				}
			}
		}
		.contain_row {
			display: flex;
			align-items: flex-start;
			padding-top: 16px;
			.left_contain {
				width: 100%;
				display: grid;
				grid-template-columns: 1fr;
				grid-gap: 16px;
				.list_name {
					font-size: $FONT_16;
					line-height: 20px;
					font-weight: 600;
					color: $TEXT_SECONDARY_ONE;
					padding-bottom: 8px;
					word-break: break-all;
				}
				.list_discription {
					font-size: $FONT_12;
					font-weight: 400;
					line-height: 14px;
					color: $TEXT_BLUE_DARK;
				}
			}
			.icon-pencil {
				border: none;
				background-color: transparent;
				font-size: $FONT_20;
				color: $TEXT_SECONDARY_ONE;
				padding: 0px;
			}
		}
		.title_button_row {
			display: flex;
			align-items: flex-start;
			padding: 16px 0px;
			.field_name {
				width: calc(100% - 16px);
				padding-right: 16px;
				font-size: $FONT_16;
				font-weight: 400;
				line-height: 20px;
				color: $TEXT_SECONDARY_TWO;
			}
			.icon-pencil {
				border: none;
				background-color: transparent;
				font-size: $FONT_20;
				color: $TEXT_SECONDARY_ONE;
				padding: 0px;
			}
		}
		.map_view {
			width: 100%;
			float: left;
			.map {
				border-radius: 8px;
				width: 100%;
				height: 370px !important;
			}
		}
		.sub_title {
			width: 100%;
			float: left;
			color: $TEXT_SECONDARY_ONE;
			font-size: $FONT_16;
			font-weight: 600;
			line-height: 20px;
			padding-bottom: 16px;
		}
		.address_text {
			width: 100%;
			float: left;
			display: flex;
			font-size: $FONT_12;
			font-weight: 400;
			line-height: 16px;
			color: $TEXT_BLUE_DARK;
			padding-bottom: 16px;
			.icon-pin-outline {
				font-size: $FONT_14;
				color: $TEXT_SECONDARY_ONE;
			}
			.icon-calender-outline-fill {
				font-size: $FONT_14;
				color: $TEXT_SECONDARY_ONE;
				margin-right: 10px;
			}
		}
		.day_time_row {
			display: flex;
			align-items: center;
			padding-bottom: 20px;
			&:last-child {
				padding-bottom: 0px;
			}
			.day_text {
				width: 50%;
				display: flex;
				align-items: center;
				font-size: $FONT_14;
				font-weight: 400;
				line-height: 18px;
				color: $BG_SECONDARY_TWO;
				span {
					color: $DAYTEXTCOLOR;
					font-size: $FONT_22;
					margin-right: 8px;
				}
			}
			.time_text {
				width: 50%;
				display: flex;
				align-items: center;
				font-size: $FONT_14;
				font-weight: 400;
				line-height: 18px;
				color: $TEXT_SECONDARY_TWO;
				p {
					margin: 0px;
					padding: 0px;
				}
				span {
					color: $DAYTEXTCOLOR;
					font-size: $FONT_22;
					margin-right: 8px;
				}
			}
		}
		.information_row {
			display: flex;
			align-items: center;
			padding-bottom: 20px;
			&:last-child {
				padding-bottom: 0px;
			}
			.linkTxt {
				width: 100%;
				display: flex;
				align-items: center;
				font-size: $FONT_14;
				font-weight: 400;
				line-height: 18px;
				color: $TEXT_SECONDARY_TWO;
				span {
					color: $DAYTEXTCOLOR;
					font-size: $FONT_22;
					margin-right: 8px;
				}
				p {
					word-break: break-all;
				}
				a:hover {
					color: rgba(
						var(--bs-link-color-rgb),
						var(--bs-link-opacity, 1)
					) !important;
				}
			}
		}
		.padding_inner {
			.input_row {
				width: 100%;
				float: left;
				position: relative;
				.customDropdown {
					.btn-primary {
						background-color: transparent;
					}
					.dropdown {
						.dropdown-menu {
							top: 48px !important;
							transform: none !important;
						}
					}
				}
				.cricle_view {
					width: 16px;
					height: 16px;
					border-radius: 50%;
					margin-right: 10px;
					float: left;
				}
			}
			.discription_txt {
				width: 100%;
				float: left;
				position: relative;
				.form-control {
					padding-right: 16px;
					height: 96px;
					resize: none;
					vertical-align: top;
				}
				.textarea_count {
					position: absolute;
					right: 16px;
					top: 70px;
					font-size: $FONT_14;
					line-height: 24px;
					font-weight: 400;
					font-family: $SFPROTEXT;
					text-align: right;
					color: $PLACEHOLDER;
				}
			}
		}
	}
	.edit_row {
		width: 100%;
		float: left;
		.title_button_row {
			align-items: center;
			padding: 22px 0px;
			.field_name {
				width: 70%;
			}
		}
		.custombtnfield {
			width: 30%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.custombtn {
				margin-right: 4px;
				font-size: $FONT_12;
				font-weight: 400;
				line-height: 14px;
				min-height: 22px;
				padding: 4px 8px;
				border-radius: 30px;
				&.outline_btn {
					margin-right: 0px;
					color: $TEXT_SECONDARY_ONE;
					border-color: $LINE_BORDER !important;
					background-color: transparent !important;
				}
			}
		}
		&.location {
			.textField {
				margin-bottom: 8px;
			}
			.location_part {
				width: 100%;
				float: left;
				border-radius: 8px;
				border: 1px solid $INPUT_BORDER;
				.map_view {
					.map {
						border-radius: 0px 0px 8px 8px;
					}
				}
				.googleDropdown .css-1pahdxg-control,
				.googleDropdown .css-yk16xz-control {
					border-radius: 0px 0px 0px 0px;
					border-top: none;
					border-right: none;
					border-left: none;
				}
			}
		}
		&.businesstiming {
			width: 100%;
			float: left;
			.timing_heading {
				width: 100%;
				float: left;
				padding: 0 0 8px 0;
				ul {
					margin: 0px;
					padding: 0px;
					list-style: none;
					li {
						float: left;
						width: 29%;
						font-size: $FONT_14;
						line-height: 18px;
						color: $TEXT_BLUE_DARK;
						font-family: $SFPROTEXT;
						&:last-child {
							width: 13%;
						}
					}
				}
			}
			.timing_list {
				width: 100%;
				float: left;
				ul {
					margin: 0px;
					padding: 0 0 8px !important;
					list-style: none;
					float: left;
					width: 100%;
					&:last-child {
						padding-bottom: 0px;
					}
					.list_col {
						float: left;
						width: 29%;
						padding-right: 8px;
						&:nth-child(1) {
							.inputbox {
								padding: 13px 8px;
							}
						}
						&:nth-child(2) {
							.inputbox {
								padding-bottom: 6px;
							}
						}
						&:nth-child(3) {
							.inputbox {
								padding-bottom: 6px;
							}
						}
						&:nth-child(4) {
							.inputbox {
								padding-left: 0px;
								padding-right: 0px;
							}
						}
						.inputbox {
							display: flex;
							border: 1px solid $INPUT_BORDER;
							border-radius: 8px;
							padding: 11px 8px;
							align-items: center;
							justify-content: space-between;
							&.disable {
								opacity: 0.5;
							}
							.CustomToggleButton-container {
								.CustomToggleButton-main {
									width: 33px;
									height: 20px;
									input:checked + .slider-button:before {
										transform: translateX(14px);
										top: 0px;
									}
									.slider-button {
										&:before {
											width: 17px;
											height: 18px;
											left: 0px;
											bottom: 0;
										}
									}
								}
							}
							.day {
								font-size: $FONT_16;
								line-height: 18px;
								color: $TEXT_SECONDARY_TWO;
								font-family: $SFPROTEXT;
							}
							.calenderContainer {
								width: 100%;
								.react-datepicker {
									width: 100%;
								}
								.custom-time-input {
									width: 100%;
									float: left;
									padding: 0px;
									border: none;
									text-align: left;
									background-color: transparent;
									display: flex;
									font-size: $FONT_18;
									line-height: 21px;
									color: $TEXT_SECONDARY_TWO;
									font-family: $SFPROTEXT;
									font-weight: 400;
									text-transform: lowercase;
									position: relative;
									.custom-date-placeholder {
										font-size: $FONT_18;
										line-height: 21px;
										color: $DATETEXT;
										&::after {
											content: "\e906";
											font-family: "angus_icon";
											font-size: 20px;
											color: $TEXT_BLUE_DARK;
											opacity: 0.5;
											position: absolute;
											right: 0px;
											top: 0px;
										}
									}
								}
							}
							.checkboxMain {
								label {
									&:before {
										width: 26px;
										height: 26px;
										border: 1px solid $TEXT_BLUE_DARK;
										padding: 2px;
										margin-right: 0px;
									}
								}
								input:checked + label {
									&::before {
										background-color: transparent;
									}
									&:after {
										content: "\e90f";
										font-family: "angus_icon";
										border: none;
										transform: none;
										width: auto;
										height: auto;
										top: 3px;
										left: 8px;
										color: $TEXT_BLUE_DARK;
										font-size: $FONT_12;
									}
								}
							}
						}
						&:last-child {
							width: 13%;
							padding-right: 0px;
							.inputbox {
								border: none;
							}
						}
					}
				}
			}
		}
		&.contact_information {
			.TextFieldNoRequired {
				.iconSection {
					&.left_icon {
						width: 40px;
						justify-content: center;
						.icon-left {
							color: $DAYTEXTCOLOR;
						}
					}
				}
				label {
					left: 40px;
					&::after {
						display: none;
					}
				}
				.form-control {
					padding-left: 40px;
					background: transparent;
				}
			}
		}
	}
	.colorDropdownWrapper {
		position: relative;
		.arrow-dropdown {
			position: absolute;
			top: 50%;
			margin-top: -8px;
			right: 14px;
			color: $DATETEXT;
		}
		.dropdownData {
			padding: 8px;
			.dropdown-item {
				background: $BG_WHITE;
				display: flex !important;
				grid-gap: 10px;
				padding: 10px 10px !important;
				border-radius: 8px;
				border-bottom: 0px !important;
				&:hover {
					background-color: $DROPDOWNHOVER !important;
					color: $TEXT_TITLE !important;
				}
			}
			.cricle_view {
				width: 16px;
				height: 16px;
				border-radius: 100px;
			}
			/* width */
			&::-webkit-scrollbar {
				width: 6px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: #f1f1f1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #073180;
				border-radius: 8px;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: $BG_PURPLE;
			}
		}
		.dropdown-menu {
			box-shadow: 0px 0px transparent;
			border: 1px solid $BORDERCOLOR;
		}
		.cricle_select_view {
			width: 16px;
			height: 16px;
			display: block;
			border-radius: 100px;
			position: absolute;
			top: 50%;
			margin-top: -8px;
			z-index: 1;
			left: 15px;
		}
		.customDropdown {
			button {
				padding: 12px 40px !important;
			}
		}
		.dropdownData {
			background: #ffffff;
			border-radius: 8px;
		}
	}
	.townWrapper {
		position: relative;
		.arrow-dropdown {
			position: absolute;
			top: 50%;
			margin-top: -8px;
			right: 14px;
			color: $DATETEXT;
		}
	}
	.onlineWrapper {
		display: flex;
		grid-gap: 10px;
		margin-bottom: 15px;
		.OnlineSwitchWrapper {
			display: flex;
			align-items: center;
			grid-gap: 10px;
			padding: 10px 16px;
			border: 1px solid $LINE_BORDER;
			border-radius: 8px;
			max-height: 47px;
			min-height: 47px;
			p {
				margin: 0px;
				font-size: 16px;
				font-weight: 400;
			}
		}
		.textField {
			margin-bottom: 0px;
		}
	}
	.countryPhoneGroup {
		&::before {
			border: 1px solid $BORDERCOLOR;
		}
		.icon-phone {
			color: $ICONCOLOR;
		}
	}
}
