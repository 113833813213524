@import "../../assets/scss/variables.scss";

.customDropdown {
  margin: 0 !important;
  border-radius: 45px;
  &.disable{
    opacity: 0.5;
    pointer-events: none;
  }
  .dropdownData {
    float: left;
    width: 100%;
    overflow-y: scroll;
    max-height: 230px;
}
  .dropdown {
    width: 100%;
    text-align: left;
    margin-bottom: 16px;

    .btn-primary {
      background: $BG_WHITE;
      font-family: $SFPROTEXT;
      color: $PLACEHOLDER;
      font-size: $FONT_14;
      padding: 12.5px 14px;
      padding-right: 30px;
      height: auto;
      width: 100%;
      border-radius: 8px;
      border: 1px solid $INPUT_BORDER;
      text-align: left;

      &:focus {
        box-shadow: none !important;
      }

      &:active {
        color: $TEXT_SECONDARY_TWO;
        background: $BG_WHITE;
      }

      &::after {
        margin: 0;
        content: "\e91b";
        font-family: "angus_icon";
        border: none;
        color: $PLACEHOLDER;
        font-size: $FONT_16;
        position: absolute;
        right: 14px;
        top: 12px;
      }
    }

    .dropdown-menu {
      padding: 0;
      border: 0;
      background-color: $BG_WHITE;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      -ms-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      -o-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      width: 100%;
      overflow: hidden;

      .dropdownData {
        .dropdown-item {
          font-family: $SFPROTEXT;
          color: $TEXT_SECONDARY_TWO;
          font-size: $FONT_14;
          padding: 12px 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          &:hover {
            color: $TEXT_WHITE;
            text-decoration: none;
            background-color: $BG_SECONDARY_ONE;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .selected {
    &.dropdown {
      .btn-primary {
        color: $TEXT_SECONDARY_TWO !important;
      }
    }
  }
}
