@import "../../assets/scss/variables.scss";

.CustomeEventWrapper {
	padding: 80px 20px;
	background-color: $BG_Body;
	.CustomeEventSec {
		.fc-header-toolbar {
			margin: 0px !important;
			.fc-toolbar-chunk {
				display: flex;

				&:first-child {
					position: absolute;
					right: 90px;
					padding: 14px 16px;
					border-radius: 5px;
					background: $BG_WHITE;
					font-size: 50px;
					min-height: 50px;
					display: flex;
					align-items: center;
					max-width: 133px;
					min-width: 133px;
					justify-content: center;
					h2 {
						font-size: 14px !important;
						color: $TEXT_SECONDARY_TWO;
						font-weight: 500 !important;
						font-family: "SF Pro Display";
					}
				}
				.fc-today-button {
					background-color: $BG_WHITE;
					border-radius: 5px;
					border: 0px;
					padding: 14px 16px !important;
					color: $BUTTONCOLOR;
					font-size: 14px;
					min-height: 50px;
					display: flex;
					align-items: center;
					text-transform: capitalize;
				}
			}

			.fc-button-group {
				min-width: 268px;
				display: grid;
				grid-template-columns: 52px 1fr 52px;
				position: relative;

				button {
					background-color: $BG_WHITE;
					border-radius: 5px;
					border: 0px;
					padding: 14px 16px !important;
					color: $BUTTONCOLOR;
					font-size: 14px;
					min-height: 50px;
					display: flex;
					align-items: center;

					&:last-child {
						grid-column: 3 / 3;
					}
				}
			}
		}
		.fc-popover {
			max-height: 300px; /* Set your desired max height here */
			overflow-y: auto; /* Enable vertical scrolling if needed */
			overflow-x: hidden;
		}

		.fc-daygrid {
			padding: 20px;
			background-color: $BG_WHITE;
			margin-top: 20px;
		}

		.fc-scrollgrid-liquid {
			border-radius: 10px;
			overflow: hidden;
			border: 1px solid $SCROLLCOLOR;
		}
		.fc-theme-standard {
			th {
				background: $TEXT_GREY_LIGHT;
				text-align: left;
				font-size: 14px;
				font-weight: 700;
				border-color: $SCROLLCOLOR;
				border-bottom: 0px;
				&:last-child {
					border-right: 0px !important;
				}
			}
		}

		.fc-daygrid-block-event .fc-event-time,
		.fc-daygrid-block-event .fc-event-title {
			padding: 1px;
			color: $TIMECOLORTEXT;
			font-weight: 700;
			padding: 0px 5px;
		}
		.fc-theme-standard {
			td {
				border: 1px solid $TIMEBORDER;
				border-left: 0px;
				&:last-child {
					border-right: 0px;
				}
			}
		}
		tr {
			&:last-child td {
				border-bottom: 0px !important;
			}
		}
	}
	.fc-daygrid-body {
		width: 100% !important;
		table.fc-scrollgrid-sync-table {
			width: 100% !important;
		}
		.fc-h-event {
			border: 0px !important;
		}
	}

	/* For events that end on a day */
	.fc-direction-ltr .fc-daygrid-event.fc-event-end,
	.fc-direction-rtl .fc-daygrid-event.fc-event-start {
		margin-left: 4px !important;

		div {
			width: 100% !important;
		}
	}

	/* For events that start on a day */
	.fc-direction-ltr .fc-daygrid-event.fc-event-start,
	.fc-direction-rtl .fc-daygrid-event.fc-event-end {
		margin-right: 4px !important;
	}
}
