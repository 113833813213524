@import "../../assets/scss/variables.scss";
.ListBusinessMain {
	padding: 0 15px;
	width: 100%;
	float: left;
	display: inline-block;
	position: relative;

	&.disable {
		-webkit-transition: all 2s ease-in-out;
		-moz-transition: all 2s ease-in-out;
		-o-transition: all 2s ease-in-out;
		-ms-transition: all 2s ease-in-out;
		transition: all 2s ease-in-out;
		.full_btn {
			opacity: 0.4;
			.active_bg {
				background-color: $CONTACTBOXCOLOR !important;
			}
			.title_left {
				.title_txt {
					color: $PLACEHOLDER;
					font-weight: 500;
				}
			}
		}
	}
	.full_btn {
		width: 100%;
		float: left;
		border: none;
		background-color: $BG_WHITE;
		box-shadow: 0px 0px 1px $BOXSHADOWColor;
		border: 1px solid transparent;
		border-radius: 8px;
		margin-bottom: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		outline: 0;
		padding: 15px 16px;
		cursor: pointer;
		position: relative;
		// flex-wrap: wrap;
		&.list_selected_bg {
			border-color: $BG_YELLOW2;
			background-color: $BG_WHITE;
			.title_left {
				.cricle_bg {
					color: $BG_SECONDARY_ONE !important;
					.selected_bg {
						opacity: 1;
					}
				}
			}
			.active_bg {
				display: none;
			}
		}
		.active_bg {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			opacity: 0.2;
			display: none;
			border-radius: 8px;
		}
		.title_left {
			float: left;
			position: relative;
			z-index: 1;
			.cricle_bg {
				position: relative;
				width: 48px;
				height: 48px;
				float: left;
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-transform: uppercase;
				font-size: $FONT_24;
				font-weight: 600;
				line-height: 30px;
				font-family: $SFPROTEXT;
				color: $TEXT_SECONDARY_ONE;
				z-index: 11;
				background-color: transparent !important;
				color: $PLACEHOLDER;
				.selected_bg {
					position: absolute;
					border-radius: 8px;
					top: 0;
					left: 0px;
					width: 100%;
					height: 100%;
					z-index: -1;
					opacity: 0.2;
				}
			}
			.title_row {
				width: calc(100% - 48px);
				float: left;
				text-align: left;
				padding: 0 0 0 24px;
				.title_txt {
					font-size: $FONT_16;
					color: $TEXT_TITLE;
					font-family: $SFPROTEXT;
					font-weight: 600;
					line-height: 22px;
					padding-bottom: 8px;
				}
				.location {
					display: flex;
					margin-left: 0px;
					font-size: $FONT_12;
					line-height: 14px;
					color: $TEXT_GREY;
					padding: 0px;
					border-radius: 30px;
					font-family: $SFPROTEXT;
					font-weight: 400;
					.icon-pin {
						font-size: $FONT_16;
						color: $TEXT_GREY;
						margin-right: 8px;
					}
				}
			}
		}
		.action_right {
			display: flex;
			align-items: center;
			z-index: 1;
			button {
				border: none;
				background-color: transparent;
				padding: 0px;
				margin: 0 12px;
				&.icon-pencil {
					color: $TEXT_SECONDARY_ONE;
					font-size: $FONT_20;
				}
				&.icon-delete {
					color: $TEXT_RED;
					font-size: $FONT_20;
				}
			}
			.CustomToggleButton-container {
				margin: 0 0px 0 12px;
				.CustomToggleButton-main {
					width: 33px;
					height: 20px;
					input:checked + .slider-button:before {
						transform: translateX(14px);
						top: 0px;
					}
					.slider-button {
						&:before {
							width: 17px;
							height: 18px;
							left: 0px;
							bottom: 0;
						}
					}
				}
			}
		}
		&:hover {
			border-color: #fbdc6e;
			-webkit-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
			-moz-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
			-ms-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
			-o-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
			box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08);
		}
	}
}
