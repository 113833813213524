@import "../../assets/scss/variables.scss";

.react-select-container {
	.react-select__control {
		padding: 0px 16px;
		margin-bottom: 16px;
		border-radius: 8px;
		border: 1px solid $LINE_BORDER;
		box-shadow: 0px 0px !important;
		min-height: 48px;
		.react-select__value-container {
			padding: 0px;
			margin: 0px;
			display: flex;
			grid-gap: 5px;
			.react-select__input-container {
				margin: 0px;
				padding: 0px;
				display: inline-grid !important;
				font-size: 0px;
			}
			.react-select__placeholder {
				font-size: 14px;
				margin: 0px;
				color: $PLACEHOLDER;
			}
			& > div {
				&:nth-last-child(-n + 2) {
					&:after {
						content: "";
					}
				}
			}
		}
	}

	.react-select__multi-value {
		background: transparent;
		border-radius: 4px !important;
		color: $TEXT_BLUE_DARK_LIGHT;
		overflow: hidden;
		margin: 0px;
		padding: 0px;
		display: flex;
		align-items: center;

		.react-select__multi-value__label {
			color: $TEXT_BLUE_DARK_LIGHT;
			padding: 0px;
		}
		.react-select__multi-value__remove {
			display: none;
		}

		&::after {
			content: ",";
		}
	}

	.react-select__multi-value ~ .react-select__input-container {
		display: inline-grid !important;
		font-size: 0px;
	}

	.react-select__menu {
		z-index: 1111;
		padding: 8px;
		border: 1px solid $DROPDOWNBORDER;
		box-shadow: 0px 0px;
		border-radius: 0px;

		.react-select__menu-list {
			padding: 0px;
			.react-select__option {
				padding: 8px;
				font-size: 14px;
				color: #1f2f47;
				font-weight: 400;
				border-bottom: 1px solid $DROPDOWNBORDER;
				margin-bottom: 2px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: row-reverse;
				&:last-child {
					border-bottom: 0px;
				}
				.react-select__control--is-focused {
					box-shadow: 0px 0px;
				}

				&.react-select__option--is-selected {
					background-color: $UPLOADBGCOLOR;
					border-radius: 8px;
				}
				input[type="checkbox"] {
					display: none;

					~ label {
						min-height: 20px;
						min-width: 20px;
						visibility: hidden;

						span::before {
							content: "";
							font-family: "angus_icon" !important;
							position: absolute;
							z-index: 1111;
							visibility: visible;
							width: 20px;
							height: 20px;
							border-radius: 2px;
							border: 1px solid $TEXT_GREY;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 12px;
							color: $TEXT_GREY;
						}
					}

					&:checked ~ label {
						transform: scale(1);
						visibility: hidden;
						position: relative;

						span::before {
							opacity: 1;
							position: absolute;
							z-index: 1111;
							visibility: visible;
							content: "\e90f";
							font-family: "angus_icon" !important;
							top: 0;
							width: 20px;
							height: 20px;
							border-radius: 2px;
							border: 1px solid $TEXT_GREY;
							left: 0;
						}
					}
				}
			}
		}
	}

	.react-select__option--is-focused {
		background-color: $SCROLLTRACK;
		border-radius: 8px;
	}
	.react-select__indicators {
		.react-select__indicator:first-child {
			padding-right: 20px;
			color: $TEXT_BLUE_DARK_LIGHT;
		}
		.react-select__indicator-separator,
		.react-select__indicator:last-child {
			display: none;
		}
	}
}
