@import "../../assets/scss/variables.scss";

.toast {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  max-width: 100% !important;
  z-index: 9999;
  text-align: center;
  flex-basis: inherit !important;
  margin: 0 auto !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 20px 0 0 0;
  display: none;
  opacity: 1 !important;

  .tostcenter {
    display: inline-block;
    min-width: inherit;
    max-width: 441px;
    border-radius: 5px;
    padding: 24px 46px 24px 46px;
    text-align: left;
    margin: 0 auto;
    background-color: $BG_WHITE;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    -ms-box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    -o-box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    position: relative;
    overflow: hidden;
    p {
      margin: 0px;
      font-family: $SFPROTEXT;
      font-size: $FONT_14;
      color: $TEXT_SECONDARY_TWO;
      font-weight: 500;
      position: relative;
      align-items: flex-start;
      justify-content: center;
      display: flex;
      align-items: center;
      &::before{
        position: relative;
        left: 0px;
        top: 0px;
        font-size: $FONT_24;
        line-height: normal;
        margin-right: 10px;
        font-family: "angus_icon";
      }
    }
  }

  &.show {
    display: block;
  }

  &.success-txt {
    .tostcenter {
      p {
        &::before{
          color: $TEXT_GREEN;
          content: "\e91a";
        }
      }
    }
  }

  &.error-txt {
    .tostcenter {
      p {
        &::before{
          color: $TEXT_RED;
          content: "\e918";
        }
      }
    }
  }

  &.warning-txt {
    .tostcenter {
      p {
        color: $TEXT_BLACK;
      }
    }
  }
}
