@import "../../assets/scss/variables.scss";

.topbar-wrap {
	text-align: left;
	position: absolute;
	width: 36%;
	top: 80px;
	z-index: 1;

	.searchbox {
		margin-left: 72px;
		max-width: 452px;

		.form-control {
			border: 0;
			padding-left: 0;
			height: 48px;
		}
	}

	.input-group {
		box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.08);
		border-radius: 8px;

		.input-group-text {
			background: $BG_WHITE;
			border: 0;
			color: $TEXT_GREY;
		}
	}
}

.listVieWrap {
	padding: 100px 20px 20px;
	background-color: $BG_GREY2;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 20px;
}
