@import "../../assets/scss/variables.scss";

.supportersModal .modal-dialog {
  max-width: 618px;
  .modal-content{
    border-radius: 25px;
    border: 0px;
    .modal-header{
        border: 0px;
      .modal-title{
       &.h4 {
         margin: 30px 0px 0px;
         font-size: 20px;
         color: $TEXT_TITLE;
         font-weight: 600;
     }
      }
    }
  }
}
.supportersModal {
  img {
    height: auto;
    margin-top: 32px;
  }
}

.support-head {
  margin-top: 44px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.supportersModal .modal-body {
  padding-top: 0;
}
