@import "../../assets/scss/variables.scss";

.dropdown-toggle::after {
	display: none !important;
}

.navWrap {
	position: sticky;
	top: 00;
	width: 100%;
	z-index: 11;
	background-color: $BG_GREY2;
}

.navbar-expand-lg {
	padding: 0px !important;
	box-shadow: 0px 8px 16px $BOXSHADOWColor;
	font-weight: 600;
	.navbar-expand {
		width: 100%;
		padding: 0;
	}
	.navbar-nav {
		.nav-link {
			padding: 17px 32px !important;
			border-right: 1px solid $BG_LIGHTGREY;
			color: $TEXT_GREY;
			&:hover,
			&:focus,
			&.active {
				color: $BG_PURPLE;
				.icon {
					color: $TEXT_YELLOW;
				}
			}
			&.active {
				background: $BG_YELLOW_LIGHT;
			}
			.icon {
				margin-right: 10px;
				font-size: 20px;
				color: $TEXT_GREY;
				transition: 0.3s;
			}
		}
	}
	.nav-item {
		&.dropdown {
			padding: 15px 34px;
			a {
				color: $TEXT_GREY;
				&.dropdown-toggle {
					font-size: 20px;
				}
				.icon {
					margin-right: 16px;
				}
			}
			.dropdown-toggle {
				&:hover,
				&:focus {
					color: $TEXT_YELLOW;
				}
			}
			&.show {
				background: $BG_YELLOW_LIGHT;
				.dropdown-toggle {
					color: $TEXT_YELLOW;
				}
			}
		}
		.dropdown-menu {
			&.show {
				left: auto;
				right: 0;
				border: none;
				padding: 0;
				box-shadow: 0px 16px 32px $BOXSHADOWColor;
				margin-top: 0;
				border-radius: 0;
			}
			.dropdown-item {
				padding: 13px 16px;
				&:hover,
				&:focus {
					color: $TEXT_YELLOW;
					background: transparent;
				}
				&:not(:last-child) {
					border-bottom: 1px solid $BG_LIGHTGREY;
				}
			}
		}
	}
}
