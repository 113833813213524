@import "../../assets/scss/variables.scss";

.full {
	margin: auto;
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	background: #1b191970;
	line-height: 27px;
	z-index: 111;

	div {
		margin: auto;
	}
}

.btn-loader {
	margin: auto;
	position: relative;
	z-index: 9999;
	left: 0px;
	right: 0px;
	top: 0;
}

.adminPanel {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 60vh;
}
