@import "../../../assets/scss/variables.scss";

.ResetPassword {
  width: 100%;
  float: left;
  position: relative;
  background-color: $BG_Body;
  .formMain {
    position: relative;
    z-index: 9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .logoauth {
      position: absolute;
      left: 50%;
      margin-left: -64px;
      top: 50%;
      margin-top: -66px;
  }

    .formCenter {
      width: 614px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      padding: 40px 125px;
      display: block;
      height: auto;
      border-radius: 8px;
      background-color: $BG_WHITE;
      position: relative;
      .logo_bg {
        left: 0px;
        top: 0px;
        position: absolute;
        width: 100%;
        img{
          max-width: 100%;
        }
      }
      .textField {
        margin-bottom: 24px;
        label{
          &::after{
            display: none;
          }
        }
      }
      img {
        display: block;
        margin: 0 auto;
      }
      .labelTitle {
        margin: 320px 0 24px 0;
        text-align: left;
        font-family: $SFPROTEXT;
        font-size: $FONT_18;
        font-weight: 400;
        line-height: 22px;
        color: $TEXT_SECONDARY_TWO;
      }
      .custombtnfield{
        padding-bottom: 72px;
      }
    }
  }
}
@media screen and ( max-height: 800px ) {
  .ResetPassword{
    .formMain{
      justify-content: flex-start;
      .formCenter{
        width: 470px;
        padding: 40px 50px;
        .labelTitle{
          margin: 200px 0 24px 0;
        }
      }
    } 
  }  
}

@media only screen and (min-width:1025px) and (max-width:1366px){
  .ResetPassword{
    .formMain{
      justify-content: flex-start;
      .formCenter{
        width: 470px;
        padding: 40px 50px;
        .labelTitle{
          margin: 200px 0 24px 0;
        }
      }
    } 
  } 
}

@media only screen and (min-width:768px) and (max-width:1024px){
  .ResetPassword{
    .formMain{
      justify-content: flex-start;
      .formCenter{
        width: 470px;
        padding: 40px 50px;
        .labelTitle{
          margin: 200px 0 24px 0;
        }
      }
    } 
  } 
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:landscape) {
  .ResetPassword{
    .formMain{
      justify-content: flex-start;
      .formCenter{
        width: 470px;
        padding: 40px 50px;
        .labelTitle{
          margin: 200px 0 24px 0;
        }
      }
    } 
  } 
}

@media only screen and (min-width:576px) and (max-width:767px){
  .ResetPassword{
    .formMain{
      align-items: flex-start;
      padding: 40px 0;
      .formCenter{
        padding: 40px 60px;
      }
    } 
  } 
}
@media only screen and (max-width:575px){
  .ResetPassword{
    .formMain{
      align-items: flex-start;
      padding: 40px 0;
      .formCenter{
        padding: 24px 24px;
      }
    } 
  } 
}