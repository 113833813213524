@import "../../assets/scss/variables.scss";

.CustomToggleButton-container {
  display: table;
  //border: 1px solid blue; //testing
  width: fit-content;
}

.CustomToggleButton-main {
  position: relative;
  width: 27px;
  height: 18px;
  margin: 0px;
  float: right;
}

.CustomToggleButton-main input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-button {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $BG_GRET_LIGHT;
  border: 1px solid $BG_GRET_LIGHT;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-button:before {
  font-family: "Courier New", Courier, monospace;
  position: absolute;
  content: "";
  font-size: 14px;
  height: 14px;
  width: 13px;
  left: 2px;
  bottom: 1px;
  background-color: $BG_WHITE;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:checked + .slider-button {
  background-color: $BG_GREEN_DARK;
  border: 1px solid $TEXT_GREEN_DARK;
}

input:checked + .slider-button:before {
  font-size: $FONT_18;
  background-color: $BG_WHITE;
  position: absolute;
  top: 1px;
}

input:focus + .slider-button {
  box-shadow: none;
}

input:checked + .slider-button:before {
  -webkit-transform: translateX(9px);
  -ms-transform: translateX(9px);
  transform: translateX(9px);
}

.slider-button.round {
  border-radius: 50px;
}

.slider-button.round:before {
  border-radius: 50%;
}
