@import "../../assets/scss/variables.scss";
.searchboxMain {
	padding: 0;
	width: 100%;
	float: left;
	display: inline-block;
	position: relative;
	.searchInput {
		width: 100%;
		border: 1px solid $INPUT_BORDER;
		background: $BG_WHITE;
		padding: 12px 34px 13px 16px;
		border-radius: 5px;
		font-family: $SFPROTEXT;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		color:$TEXT_BLUE_DARK_LIGHT;
		font-size: $FONT_14;
		&::-webkit-input-placeholder {
			color: $PLACEHOLDER;
		}
		&::-moz-placeholder {
			color: $PLACEHOLDER;
		}
		&:-ms-input-placeholder {
			color: $PLACEHOLDER;
		}
		&:-moz-placeholder {
			color: $PLACEHOLDER;
		}
		&:focus {
			outline: none;
		}
	}
	.close-icon {
		border: none;
		background-color: transparent;
		display: inline-block;
		vertical-align: middle;
		outline: 0;
		padding: 0px;
		position: absolute;
		right: 16px;
		top: 16px;
		cursor: pointer;
		color: $PLACEHOLDER;
		&.icon-search{
			color:$PLACEHOLDER;
		}
		&.icon-close{
			color:#2E3A59;
			font-size: 12px;
			right: 18px;
			top: 18px;
		}
	}
	.searchInput:not(:valid)~.close-icon {
		&:after {
			display: none;
		}
	}
}