@import "../../assets/scss/variables.scss";
.ProfilePage {
	padding: 0 15px;
	width: 100%;
	float: left;
	display: inline-block;
	position: relative;
	.editprofile{
		width: 100%;
		float: left;
		padding: 40px 50px 0;
		.fileInput{
			.fileUploadMain{
				width: auto;
				height: auto;
				margin-bottom: 50px;
				.dropzoneCustom {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 0px;
					padding: 0px;
					.txtDropzone{
						.uploadedImg{
							width: 83px;
							height: 83px;
							display: flex;
							align-items: center;
							justify-content: center;
							overflow: hidden;
							background-color: $UPLOADBGCOLOR;
							border-radius: 50%;
							img{
								max-width: 100%;
							}
						}
					}
					.change_txt{
						font-size: $FONT_12;
						font-family: $SFPROTEXT;
						line-height: 14px;
						color: $UPLOADTEXT;
						padding: 5px 0 0;
					}
					.ProifleName{
						width: 83px;
						height: 83px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size:24px;
						font-weight: 600;
						color: $TEXT_SECONDARY_ONE;
						background-color: rgba(50, 14, 59, 0.05);
					}
				}
			}
		}
		.input_label{
			width: 100%;
			float: left;
			font-size: $FONT_14;
			line-height: 17px;
			font-family: $SFPROTEXT;
			font-weight: 400;
			color: $TEXT_BLUE_DARK;
			padding-bottom: 8px;
		}
		.TextFieldNoRequired{
			margin-bottom: 8px;
			.form-control{
				&:disabled{
					background-color: $BG_WHITE;
					opacity: 0.5;
					color: $PLACEHOLDER;
				}
			}
		}
		.custombtnfield{
			width: 100%;
			float: left;
			padding: 10px 0 0 0px;
			margin: 0px;
			.custombtn{
				margin-bottom: 16px;
				font-size: $FONT_16;
				font-family: $SFPROTEXT;
				line-height: 20px;
				padding: 0 0px;
				min-height: 36px;
				border-radius: 8px;
				&.outline_btn{
					color: $TEXT_BLUE_DARK !important;
				}
			}
		}
	}
	.change_pasword{
		width: 100%;
		float: left;
		padding: 40px 50px 0;
		.top_title_row{
			display: flex;
			justify-content: space-between;
			.change_password_title{
				width: 100%;
				float: left;
				font-size: $FONT_14;
				line-height: 30px;
				font-family: $SFPROTEXT;
				font-weight: 400;
				color: $TEXT_SECONDARY_TWO;
				padding-bottom: 8px;
			}
			.signfield{
				display: flex;
				padding-bottom: 8px;
				.custombtn{
					padding: 0px;
					margin: 0px;
					min-height: 30px;
					font-size: $FONT_12;
					font-weight: 400;
					line-height: 14px;
					padding: 4px 9px;
					border-radius: 30px;
					margin-left: 6px;
					width: 60px;
					&:disabled{
						background-color: $BG_SECONDARY_ONE;
						opacity: 0.5;
						span{
							color: $TEXT_WHITE;
							opacity: 1;
						}
						
					}
				}
			}
		}

		.TextFieldNoRequired{
			margin-bottom: 8px;
			.form-control{
				&:disabled{
					background-color: $BG_WHITE;
					opacity: 0.5;
					color: $PLACEHOLDER;
				}
			}
		}
		.custombtnfield{
			width: 100%;
			float: left;
			padding: 10px 0 0 0px;
			margin: 0px;
			.custombtn{
				margin-bottom: 16px;
				font-size: $FONT_16;
				font-family: $SFPROTEXT;
				line-height: 20px;
				padding: 0 0px;
				min-height: 36px;
				border-radius: 8px;
				&.outline_btn{
					color: $TEXT_BLUE_DARK !important;
				}
			}
		}
	}
}